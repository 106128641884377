// Gauge

// ___________________________________________________________________

import * as React from 'react'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

const Gauge = () => (
  <svg viewBox="0 0 43.38 43.68">
    <title>Gauge icon</title>
    <path d="M21.69,1a21,21,0,0,1,14.83,35.8A21,21,0,0,1,6.86,7.16,20.84,20.84,0,0,1,21.69,1m0-.72A21.7,21.7,0,1,0,37,6.66,21.63,21.63,0,0,0,21.69.3Z" />
    <path d="M29.59,12.59l-.13,0-8.61,2.66a5.22,5.22,0,1,0,6.53,6.53L30,13.19A.46.46,0,0,0,29.59,12.59Zm-7.54,5.29a2.72,2.72,0,1,1-1.92.8A2.7,2.7,0,0,1,22.05,17.88Z" />
    <path d="M21.69.72h0a20.38,20.38,0,0,1,14,5.52L32.63,9.32a16.21,16.21,0,0,0-21.88,0L7.68,6.25a20.27,20.27,0,0,1,14-5.53m0-.72a21.22,21.22,0,0,0-15,6.23l4.09,4.09a15.5,15.5,0,0,1,21.9,0l4.09-4.09A21.21,21.21,0,0,0,21.69,0Z" />
    <path d="M36.72,7.25a20.46,20.46,0,0,1,5.51,13.24H37.87a16.15,16.15,0,0,0-4.23-10.16l3.08-3.08m0-1-4.09,4.09h0a15.47,15.47,0,0,1,4.53,10.52.37.37,0,0,0,.38.37h5a.38.38,0,0,0,.38-.39A21.2,21.2,0,0,0,36.73,6.23Z" />
    <path d="M6.65,7.25l3.08,3.08A16.15,16.15,0,0,0,5.5,20.49H1.15A20.45,20.45,0,0,1,6.65,7.25m0-1A21.22,21.22,0,0,0,.42,20.81a.39.39,0,0,0,.38.4h5a.38.38,0,0,0,.38-.37,15.42,15.42,0,0,1,4.53-10.52L6.65,6.23Z" />
  </svg>
)

export default Gauge
