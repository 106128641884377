// Beaker

// ___________________________________________________________________

import * as React from 'react'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

const Beaker = () => (
  <svg viewBox="0 0 64.15 68.52">
    <title>Beaker icon</title>
    <path
      fill={theme.colors.primary}
      d="M60,14.06h.41a2.18,2.18,0,0,0,2.18-2.17v-1a2.19,2.19,0,0,0-2.18-2.18H44.31a2.18,2.18,0,0,0-2.17,2.18v1a2.17,2.17,0,0,0,2.17,2.17h.42V37l-.95.25-6.66-13.7V5.31h.41a2.18,2.18,0,0,0,2.18-2.18V2.18A2.18,2.18,0,0,0,37.53,0H21.47a2.18,2.18,0,0,0-2.18,2.18v.95a2.18,2.18,0,0,0,2.18,2.18h.41v18.2L0,68.52H60V63.28a15.87,15.87,0,0,0,0-21.47V14.06ZM44.31,12.88a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1H60.38a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1H44.31Zm14.47,1.18V40.64a15.88,15.88,0,0,0-10.6-4.06,15.31,15.31,0,0,0-2.26.18V14.06ZM21.47,4.12a1,1,0,0,1-1-1V2.18a1,1,0,0,1,1-1H37.53a1,1,0,0,1,1,1v.95a1,1,0,0,1-1,1H21.47ZM35.93,5.31V23.05H23.07V5.31Zm-34,62,21-43.09h13.3l6.49,13.35a15.95,15.95,0,0,0-.48,29.74Zm52.3,0a16.36,16.36,0,0,0,2.32-1.19l.58,1.19Zm4.58,0h-.36l-.9-1.86a13.59,13.59,0,0,0,1.26-1ZM63,52.55a14.7,14.7,0,0,1-3,8.88c-.2.25-.39.5-.6.74s-.39.44-.59.66A15.76,15.76,0,0,1,57,64.39l-.48.36-.51.32a14.71,14.71,0,0,1-7.82,2.26,15.31,15.31,0,0,1-2.26-.19c-.2,0-.4,0-.59-.09l-.6-.14a14.76,14.76,0,0,1-1.57-28.25l.56-.2.59-.16.42-.11.6-.14c.19,0,.39-.06.59-.09a15.31,15.31,0,0,1,2.26-.19,14.73,14.73,0,0,1,10.6,4.5c.2.21.4.43.59.66s.41.49.6.74A14.66,14.66,0,0,1,63,52.55Z"
    />
    <polygon
      fill={theme.colors.primary}
      points="50.29 51.95 49.63 51.95 48.33 51.95 46.61 51.95 48.16 48.93 48.49 48.27 48.83 47.61 50.05 45.22 51.25 42.89 50.72 42.62 50.19 42.34 48.18 46.27 47.84 46.93 47.51 47.59 45.92 50.69 45.33 51.85 44.73 53.01 44.66 53.14 44.73 53.14 45.33 53.14 45.92 53.14 48.03 53.14 49.75 53.14 46.3 59.87 45.92 60.62 45.33 61.78 45.11 62.21 45.33 62.32 45.64 62.48 45.92 62.63 46.16 62.75 50.63 54.03 50.97 53.36 51.31 52.7 51.69 51.95 50.95 51.95 50.29 51.95"
    />
  </svg>
)

export default Beaker
