// Lock

// ___________________________________________________________________

import * as React from 'react'
import styled from 'styled-components'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

const Lock = () => (
  <SVG viewBox="0 0 86.1 56.34">
    <title>Atom icon</title>
    <path
      className="cls-1"
      d="M42.23,34.89c-.45-.09-.9-.17-1.34-.27L33,32.82l-4.38-1a2.61,2.61,0,0,0-.32-.06,8.57,8.57,0,0,1-4.87,2.55c-.3.73-.6,1.43-.9,2.14a2,2,0,0,1-.42.25,4.5,4.5,0,0,1-1.31.27,8.08,8.08,0,0,1-1.95-.3,2.49,2.49,0,0,1-.4-.2.82.82,0,0,0-.26-.1l-.45-.11-.28-.07c-.06-.16-.19-.18-.34-.17l-.27-.26c-.08-.21.12-.31.18-.46.24-.58.51-1.15.74-1.73.12-.29,0-.49-.27-.66s-.27-.21-.41-.31l-.41-.28c-.25-.29-.49-.58-.75-.85l-.27-.1c.05-.22-.11-.34-.22-.49a3.36,3.36,0,0,1-.56-1,3.18,3.18,0,0,0,1.46-.32,15.2,15.2,0,0,0,3.52-2.23c.41-.32.8-.67,1.2-1s.91-.77,1.34-1.18c.28-.26.52-.56.78-.85s.62-.67.92-1a7,7,0,0,0,.49-.59c.42-.61.85-1.21,1.25-1.84a4.45,4.45,0,0,0,.56-1.26.76.76,0,0,0-.42-.9,3.63,3.63,0,0,0-.79-.39c-.31-.07-.34-.24-.33-.49a1.87,1.87,0,0,1,0-.34,3.81,3.81,0,0,0,.17-1.25l.09-.21.42.09.26.09.41.28A3.47,3.47,0,0,1,27.54,18l.15.34c-.09.42-.1.85-.16,1.35l.24.17a7.86,7.86,0,0,1,1.79,2.23,5.44,5.44,0,0,1,.61,1.5c-.38,0-.75-.06-1.13-.07a.51.51,0,0,0-.31.09,4.12,4.12,0,0,0-1.31,1.5l0,.12c-.08.18-.17.35-.26.52s-.1.35-.17.52a1.16,1.16,0,0,0-.17.77l-.25.6v.08c.1.14,0,.26,0,.39-.07.45.05.63.49.73.21,0,.42.06.62.1l2.2.52L32,30l1.83.43c1,.21,2,.4,2.94.62.83.18,1.66.38,2.48.59s1.85.38,2.75.69v.6a2.29,2.29,0,0,0,.08.82,3.27,3.27,0,0,1,.09.56ZM24.56,29.57l.45-.08a1.5,1.5,0,0,0,1-.77,1.17,1.17,0,0,0-.13-1.54,4.17,4.17,0,0,1-.37-.36l-.17-.11c-.3.06-.59-.11-.91,0a1.6,1.6,0,0,0-.94.67,1.32,1.32,0,0,0-.21,1.15A1.37,1.37,0,0,0,24.56,29.57Z"
    />
    <path
      className="cls-2"
      d="M67.65,14.32,66,14c-.51.41-1,.81-1.5,1.18a7.56,7.56,0,0,1-3,1.28,1.45,1.45,0,0,0-.37.11l-.16.22c-.26.62-.52,1.24-.77,1.87l-.53,1.41c-.14.37-.29.74-.44,1.1s-.29.71-.43,1.07l-.05.1h0a2.33,2.33,0,0,0-.33.14,2.09,2.09,0,0,1-.29.16,4.32,4.32,0,0,1-2.2.07,16.75,16.75,0,0,1-1.71-.5,5.63,5.63,0,0,1-.76-.42l.13-.1c.25-.61.49-1.22.73-1.82l1.3-3.27c.12-.31.24-.62.37-.94l-.73-.47-.16,0-.52-.43a.22.22,0,0,0-.25-.17c-.11-.29-.38-.42-.59-.61l-.35-.43-.46-.76.28-.09c.23-.05.47-.08.69-.14a9,9,0,0,0,2.46-1.27c.58-.37,1.12-.8,1.66-1.22a22.63,22.63,0,0,0,2-1.69,31.22,31.22,0,0,0,2.48-2.66c.37-.47.76-.94,1.1-1.44a12,12,0,0,0,.75-1.31,2.65,2.65,0,0,0,.36-1.41,9.24,9.24,0,0,1,.79.61,9.45,9.45,0,0,1,1,1,7.08,7.08,0,0,1,1.36,2.59l-.13.09c-.15,0-.29-.1-.44-.13a1,1,0,0,0-.81.09,4.74,4.74,0,0,0-.64.53,4.47,4.47,0,0,0-.87,1.39,2.43,2.43,0,0,0-.33,1.14.26.26,0,0,0-.17.27c0,.11,0,.22,0,.34a1.39,1.39,0,0,0-.17.6l-.06.05c0,.4,0,.8,0,1.2,0,.13.08.19.21.22.31.07.62.17.93.22a8,8,0,0,0,1.61.29v.6l.07.16A2.27,2.27,0,0,0,67.65,14.32Z"
    />
    <path
      className="cls-1"
      d="M53.43,21.8a5.63,5.63,0,0,0,.76.42,16.75,16.75,0,0,0,1.71.5,4.32,4.32,0,0,0,2.2-.07,2.09,2.09,0,0,0,.29-.16,2.33,2.33,0,0,1,.33-.14c-.14.38-.27.78-.43,1.16l-1.51,3.81a8.93,8.93,0,0,1,1.69,3.95l.81.38.44.49a1,1,0,0,0,.34.52,1.75,1.75,0,0,0,0,.33,6.6,6.6,0,0,1,.21,1.47l-.08.21a3.68,3.68,0,0,1-.15,1,6.09,6.09,0,0,1-1,2,2.74,2.74,0,0,1-.73.75,5.37,5.37,0,0,0-.57.5,1.48,1.48,0,0,0-.36.1,2,2,0,0,1-1,.25l-.13.07L56,39.29,55.46,39l-1.69,1c-.16.09-.34.14-.5.23l-.11.16-.06,0a2.64,2.64,0,0,0-.93-1.5,2.55,2.55,0,0,0-.89-.6.43.43,0,0,0-.43-.17l-.26-.18a6,6,0,0,0,.21-1c0-.16.05-.33.07-.5a4.73,4.73,0,0,0,.05-.75.37.37,0,0,0-.22-.28,10,10,0,0,0-1-.2,3.06,3.06,0,0,1-.79-.07.56.56,0,0,0-.58.07,6.31,6.31,0,0,0-.76.07,3.28,3.28,0,0,0-2,1.2,1.28,1.28,0,0,0-.23.45c-.08.32-.12.66-.18,1s-.13.68-.19,1a6.55,6.55,0,0,1-1.8-2.08.42.42,0,0,1,.39-.19h.25a9.72,9.72,0,0,0,1.92-.86,17.15,17.15,0,0,0,1.74-1.18A14.36,14.36,0,0,0,49,33.31l.21-.15c.45-.38.93-.74,1.35-1.16s.73-.87,1.12-1.27a13.75,13.75,0,0,0,1.06-1.23c.1-.13.22-.26.13-.41s-.19-.32-.42-.32l-.25,0-1.3-.56a.52.52,0,0,1-.3-.67c.33-.84.67-1.68,1-2.53.26-.66.51-1.32.78-2,.19-.46.4-.91.6-1.37Zm-.88,14.29a1.74,1.74,0,0,0,1.62-1.15,2.06,2.06,0,0,0,.06-.9,1.22,1.22,0,0,0-.72-.93,1.39,1.39,0,0,0-1.47,0c-.11.07-.23.11-.34.18a.85.85,0,0,0-.25.21A1.85,1.85,0,0,0,51.16,35a1,1,0,0,0,.43.62l.21.14A.76.76,0,0,0,52.55,36.09Z"
    />
    <path
      className="cls-3"
      d="M1.93,25.78l-.12-.1c.05-.18.19-.18.34-.18a2.78,2.78,0,0,0,.49-.08,6.78,6.78,0,0,0,2.1-1c.64-.4,1.25-.85,1.86-1.3S7.54,22.39,8,22a1.11,1.11,0,0,1,.78-.12l.15.18a.5.5,0,0,1,0,.34,1.58,1.58,0,0,0,0,.78,1.86,1.86,0,0,0,.65,1.15,1.78,1.78,0,0,0,1.27.47,2.41,2.41,0,0,0,1.33-.45A1.83,1.83,0,0,0,13,23a2.17,2.17,0,0,0-.21-1.36,2.06,2.06,0,0,0-1-.89,1.91,1.91,0,0,0-1.41,0l-.22.12c-.25.08-.4,0-.39-.28a.54.54,0,0,1,.13-.3c.09-.12.22-.22.32-.34l1.16-1.41c.23-.28.46-.57.68-.86s.43-.59.64-.89a6.43,6.43,0,0,0,.79-1.43c.16-.38.11-.78.25-1.14l.35.29-.1.16c0,.15,0,.31-.05.46a2.71,2.71,0,0,1-.58,1.19,1.66,1.66,0,0,0-.41.9l-.08.24a2,2,0,0,1-.58.68.48.48,0,0,0-.15.59h1.57a1.31,1.31,0,0,1,.34,0,2.88,2.88,0,0,1,1.83,1.51,3.62,3.62,0,0,1,.35,1.49,1.41,1.41,0,0,0,.06.63l.19-.2c0-.13.05-.24.17-.26A.74.74,0,0,1,17,22a4.86,4.86,0,0,1,.67.25,1.55,1.55,0,0,0,.73.15c0,.38-.07.76-.13,1.13a5.18,5.18,0,0,1-1,2.15,4.6,4.6,0,0,1-1.31,1.21,3.13,3.13,0,0,1-1.69.42L14,27.23c-.57-.14-.57-.13-1.12.22-.34.21-.7.38-1.05.56-.12,0-.19-.12-.35,0a2.44,2.44,0,0,1-.62.11l-.29.05a2.31,2.31,0,0,1-.37,0c-.26,0-.52-.11-.78-.17a2.59,2.59,0,0,1-1.67-1.27,3.32,3.32,0,0,1-.44-1.82,4.05,4.05,0,0,1,.08-1.1.28.28,0,0,0-.17-.38,1.58,1.58,0,0,0-.33.22.4.4,0,0,1-.34.13c-.21,0-.32.09-.45.25s-.32.39-.5.42a2.76,2.76,0,0,0-1.27.71,2,2,0,0,1-.67.33A5.43,5.43,0,0,1,1.93,25.78Z"
    />
    <path
      className="cls-3"
      d="M17.5,36.18l.28.07.45.11a.82.82,0,0,1,.26.1,2.49,2.49,0,0,0,.4.2,8.08,8.08,0,0,0,1.95.3,4.5,4.5,0,0,0,1.31-.27,2,2,0,0,0,.42-.25l-.46,1.26,0,.2-.35.65c-.09.18-.14.41-.28.51a1.72,1.72,0,0,1-.82.41.59.59,0,0,0-.51.26,6.48,6.48,0,0,1-.87,1.14.61.61,0,0,0-.13.69c.32,0,.64-.1,1-.12a3.53,3.53,0,0,1,1.57.25,2.52,2.52,0,0,1,1.51,1.6,3.39,3.39,0,0,1,.21,1.41,1,1,0,0,0,0,.35l.16.09a8,8,0,0,1-1.65,3.19A8.48,8.48,0,0,1,19,50.55c-.09,0-.19.06-.28.09,0-.22-.16-.16-.27-.15-.29,0-.57.13-.86.16a5,5,0,0,1-.54-.08,2.3,2.3,0,0,1-1.15-.34,2.7,2.7,0,0,1-1.33-1.91c-.05-.24,0-.48-.08-.71a1.72,1.72,0,0,1,0-.9,1.88,1.88,0,0,0,0-.74c-.22.1-.3.35-.55.3s-.47.1-.62.33a.86.86,0,0,1-.65.42,1.43,1.43,0,0,0-.75.33c-.4.29-.75.7-1.32.66l-.11.05a5.36,5.36,0,0,1-1.12.19l-.11.1L9,48.17a.55.55,0,0,1,.4-.18,4.77,4.77,0,0,0,1.73-.61c.45-.23.87-.52,1.3-.79a23.5,23.5,0,0,0,2.07-1.53,8,8,0,0,0,.73-.57l.15-.08a.69.69,0,0,1,.61,0c.17.11.12.34.07.48a1.84,1.84,0,0,0,0,.74,2,2,0,0,0,2.12,1.73,2,2,0,0,0,.54-.09,2.24,2.24,0,0,0,1.1-.59A2.45,2.45,0,0,0,20.05,44a1.93,1.93,0,0,0-1.63-1,2.6,2.6,0,0,0-1.14.25h-.17c-.05-.1-.1-.18-.13-.26a1.34,1.34,0,0,1,.12-.24c.4-.49.8-1,1.21-1.47s.7-.79,1-1.21c.12-.15.35-.32.19-.61H19l-.52-.08a.83.83,0,0,0-.51-.09.47.47,0,0,0-.44-.17l-.59-.09c-.09-.13-.17-.26-.35-.26l-.09-.17a4.3,4.3,0,0,1,.23-.69c.19-.38.32-.78.47-1.17A3.92,3.92,0,0,1,17.5,36.18Z"
    />
    <path
      className="cls-3"
      d="M83.27,6.15l.33.31c-.18.07-.09.22-.09.33A2,2,0,0,1,83,8.17a1.65,1.65,0,0,0-.39.69A1.88,1.88,0,0,1,82,9.94a.81.81,0,0,0-.26.69,4.44,4.44,0,0,1,.67-.11,3.5,3.5,0,0,1,1.88.37,2.53,2.53,0,0,1,1.33,1.66,3.05,3.05,0,0,1,.15,1c0,.19,0,.38.25.42a5.59,5.59,0,0,1-.52,1.79,8,8,0,0,1-1.66,2.48,7.58,7.58,0,0,1-1.95,1.47,4.31,4.31,0,0,1-1.29.54c0-.13,0-.17-.18-.17h-.94a1.81,1.81,0,0,1-.47,0A2.77,2.77,0,0,1,77.35,19a2.47,2.47,0,0,1-.48-1.1c0-.29-.13-.57-.16-.86a1.44,1.44,0,0,1,0-.46,2.8,2.8,0,0,0,0-1c-.14.11-.24.23-.35.25a1,1,0,0,1-.42-.08c-.12.14-.26.27-.38.41a1,1,0,0,1-.66.38.87.87,0,0,0-.39.14c-.24.15-.45.35-.68.49a2.3,2.3,0,0,1-.79.38,8.13,8.13,0,0,0-1,.21c-.16,0-.37,0-.48.17l-.37-.44a3.18,3.18,0,0,0,1.37-.2c.45-.2.89-.42,1.34-.63l.18-.1c.47-.33,1-.65,1.41-1,.68-.5,1.35-1,2-1.55a.71.71,0,0,1,1.06,0A2.35,2.35,0,0,0,78.51,15a1.92,1.92,0,0,0,1.21,1.51,2.6,2.6,0,0,0,.92.09,1.61,1.61,0,0,0,1-.33,2.34,2.34,0,0,0,.95-1.82,1.7,1.7,0,0,0-.26-.86,1.85,1.85,0,0,0-2.4-.87.79.79,0,0,1-.6.06.63.63,0,0,1,.14-.61l1.46-1.72a7.64,7.64,0,0,0,.51-.62c.42-.6.84-1.2,1.23-1.81a2.81,2.81,0,0,0,.52-1.53A1.27,1.27,0,0,1,83.27,6.15Z"
    />
    <path
      className="cls-3"
      d="M55.74,50.14A8,8,0,0,1,53,54.41,6.79,6.79,0,0,1,51,55.66a.57.57,0,0,0-.53-.08,2.18,2.18,0,0,1-.84.07,2.6,2.6,0,0,1-2.13-.94,2.84,2.84,0,0,1-.55-.84,3.26,3.26,0,0,1-.2-1.24,5.32,5.32,0,0,1,.07-1.32l-.17-.3c-.11-.07-.14.08-.2.15s-.17.24-.32.18-.39,0-.53.23a.91.91,0,0,1-.63.45,1.46,1.46,0,0,0-1,.5,2.16,2.16,0,0,1-1.12.55l-.32.08-.9.18-.16.1c-.1-.11-.19-.23-.29-.35l.58-.1a6,6,0,0,0,2.2-.89,22.41,22.41,0,0,0,2.34-1.65c.42-.31.82-.64,1.23-1a1.22,1.22,0,0,1,.82-.07l.11.16a1.88,1.88,0,0,1,0,.33,2,2,0,0,0,.15,1.28,1.52,1.52,0,0,0,.81.84,2,2,0,0,0,1.24.21,2.12,2.12,0,0,0,1.55-1,1.92,1.92,0,0,0,0-2,1.78,1.78,0,0,0-1.61-.94,2.76,2.76,0,0,0-1.06.22c-.25.08-.33,0-.35-.25a.41.41,0,0,1,.15-.33l.93-1.11c.35-.42.69-.85,1-1.28s.5-.59.71-.9.41-.73.63-1.09a3.21,3.21,0,0,0,.53-1.75l.26.24a2.54,2.54,0,0,0,0,.39,2,2,0,0,1-.42,1.25,3.16,3.16,0,0,0-.44.66c-.13.26-.16.58-.31.82a3.38,3.38,0,0,1-.6.71.48.48,0,0,0-.1.52h.18a8,8,0,0,1,.84-.08c.22,0,.44.07.66.08a2.24,2.24,0,0,1,1.47.65,3,3,0,0,1,.95,2.26c0,.25,0,.49,0,.73S55.65,50.14,55.74,50.14Z"
    />
    <path
      className="cls-3"
      d="M69.56,31.75l.26.25c0,.15,0,.31,0,.47a1.41,1.41,0,0,1-.32,1,3.28,3.28,0,0,0-.2.31,4,4,0,0,0-.53,1.08.93.93,0,0,1-.3.54,1.75,1.75,0,0,0-.55.73.29.29,0,0,0,.39.15,2.26,2.26,0,0,1,.93-.07,2.77,2.77,0,0,1,1.9.77,2.9,2.9,0,0,1,.91,2.1c0,.29,0,.58,0,.86,0,.12.07.14.16.14-.1.34-.17.69-.3,1a8.17,8.17,0,0,1-2.32,3.25,6.85,6.85,0,0,1-2,1.26l-.11-.08-.06.17-.18.07c0-.25-.2-.18-.3-.16a3.53,3.53,0,0,1-1.55,0,2.54,2.54,0,0,1-1.81-1.31,3.87,3.87,0,0,1-.4-2.73,1.86,1.86,0,0,0,0-.41c-.27-.11-.33.15-.5.21a.53.53,0,0,0-.68.21,1.06,1.06,0,0,1-.83.5.47.47,0,0,0-.27.1l-.53.41a1.75,1.75,0,0,1-.63.41,7.44,7.44,0,0,1-1.48.28c-.15,0-.29,0-.34.18l-.3-.34c.19-.1.39,0,.59-.07a6.86,6.86,0,0,0,2.44-1.08c.67-.46,1.34-.92,2-1.4.43-.32.85-.66,1.26-1a.79.79,0,0,1,.55-.17c.31,0,.35,0,.36.34v.77a1.48,1.48,0,0,0,.27.91,1.94,1.94,0,0,0,1.7.91,2.16,2.16,0,0,0,1.78-1,2.06,2.06,0,0,0,.34-1.4A1.88,1.88,0,0,0,68,38.48a1.82,1.82,0,0,0-1.57-.23,5.08,5.08,0,0,1-.51.17.26.26,0,0,1-.33-.13.33.33,0,0,1,.08-.36c.57-.67,1.13-1.37,1.71-2a18.1,18.1,0,0,0,1.5-2.09A3.77,3.77,0,0,0,69.56,31.75Z"
    />
    <path
      className="cls-3"
      d="M27.69,18.35,27.54,18a3.47,3.47,0,0,0-1.28-1.53l-.41-.28c.09-.78.23-1.56.38-2.33a3,3,0,0,1,1.05.71.57.57,0,0,0,.57.11,2.26,2.26,0,0,0,1.24-.94,1.9,1.9,0,0,0,.36-1.31A1.63,1.63,0,0,0,29,11.35c-.33-.27-.62-.63-1.13-.58l-.11,0a2.08,2.08,0,0,0-.5,0c-.3.08-.6.19-.91.29a.45.45,0,0,1-.07-.59A11.42,11.42,0,0,1,27,9.48c.4-.47.83-.9,1.19-1.39A24.83,24.83,0,0,0,29.7,5.9a2.19,2.19,0,0,0,.38-1.28,1,1,0,0,1,.1-.36l.33.31c-.25.08-.18.29-.17.47a1.73,1.73,0,0,1-.48,1.27,2.18,2.18,0,0,0-.38.78,1.55,1.55,0,0,1-.32.67c-.11.13-.25.24-.36.37a.54.54,0,0,0-.17.49h1.25a2.59,2.59,0,0,1,.67.07,2.92,2.92,0,0,1,1.76,1.37,4.18,4.18,0,0,1,.43,1.74c0,.12,0,.18.17.18l0,.17-.14.06.09.11a4.3,4.3,0,0,1-.46,1.5,8,8,0,0,1-1.59,2.42,8.4,8.4,0,0,1-2.07,1.59Z"
    />
    <path
      className="cls-4"
      d="M53,21.62c-.2.46-.41.91-.6,1.37-.27.66-.52,1.32-.78,2-.33.85-.67,1.69-1,2.53a.52.52,0,0,0,.3.67l1.3.56.25,0c.23,0,.33.17.42.32s0,.28-.13.41a13.75,13.75,0,0,1-1.06,1.23c-.39.4-.72.87-1.12,1.27s-.9.78-1.35,1.16l-.21.15a14.36,14.36,0,0,1-1.55,1.25,17.15,17.15,0,0,1-1.74,1.18,9.72,9.72,0,0,1-1.92.86h-.25a.42.42,0,0,0-.39.19,6,6,0,0,1-.7-1.38c-.07-.18-.16-.35-.24-.53l-.09-.6a1.65,1.65,0,0,0,1-.23c.5-.27,1-.51,1.49-.8s1.2-.78,1.78-1.19.9-.64,1.32-1a17.83,17.83,0,0,0,1.35-1.25c.44-.48.83-1,1.25-1.5a.41.41,0,0,0,0-.63l-.46-.37a.33.33,0,0,1-.11-.45c.11-.24.2-.49.3-.74l.78-1.94,1-2.42.34-.85h.09l.34.27Z"
    />
    <path
      className="cls-5"
      d="M27.4,25.24l0-.12a4.12,4.12,0,0,1,1.31-1.5.51.51,0,0,1,.31-.09c.38,0,.75,0,1.13.07l2.54.61L40.5,26l3.54.82-.06.23a6.35,6.35,0,0,0-1,1.42.42.42,0,0,1-.51.21c-.63-.16-1.27-.29-1.91-.44-.86-.2-1.73-.42-2.6-.6s-1.92-.45-2.88-.67l-2.15-.52L31.08,26l-2.57-.57Z"
    />
    <path
      className="cls-5"
      d="M42,32.91v-.6c0-.23,0-.45,0-.67s.09-.7.14-1.06a1.11,1.11,0,0,0,.17-.62.21.21,0,0,0-.19-.24l-1.83-.43c-.86-.18-1.73-.32-2.58-.53-1.23-.31-2.47-.56-3.7-.87-1.08-.27-2.17-.48-3.25-.75-.72-.18-1.43-.35-2.15-.51-.55-.13-1.09-.24-1.64-.35.07-.17.05-.37.17-.52a1.26,1.26,0,0,1,.39,0c1.1.3,2.22.51,3.33.77s2.24.55,3.37.77c.81.16,1.59.4,2.39.59l2.9.63c.87.19,1.74.38,2.6.59.21.05.51.05.47.43,0,.13.16.2.29.14s.39-.22.59-.33c.62-.34.75-.35,1.25.14a1.57,1.57,0,0,0,1.63.35A2.2,2.2,0,0,0,47.54,29,2.75,2.75,0,0,0,47.91,28l0-.21a2.28,2.28,0,0,0-.6-1,.6.6,0,0,1-.07-.87c.13-.19.26-.37.38-.56a1,1,0,0,0,.25-.7l1.22-.33.86-2.15L51,19.36l.1-.18a.86.86,0,0,0,.25.66.48.48,0,0,0,.15.42l0,.15c-.06.17-.12.35-.18.51-.31.8-.61,1.59-.93,2.38s-.7,1.68-1,2.52a1.52,1.52,0,0,0-.07,1.3v.23a6.09,6.09,0,0,1-1,2,6,6,0,0,1-.71.79,6.47,6.47,0,0,1-2.09,1.07c-.25.09-.49.2-.73.3a11.59,11.59,0,0,0-1.67,1A2.08,2.08,0,0,1,42,32.91Z"
    />
    <path
      className="cls-4"
      d="M14,27.23l.21.08c.1.05.2.12.31.17l1.52.58a2,2,0,0,0,1.36,0,4.14,4.14,0,0,0,2.27-1.91,10.11,10.11,0,0,0,.48-1.06,2.78,2.78,0,0,0,.28-.9l.07-.05a1,1,0,0,0,.28-.16c.45-.49.89-1,1.32-1.49.2-.23.38-.46.56-.69s.5-.71.77-1.06a6,6,0,0,0,1.06-2c.14-.48.11-.54-.34-.72-.23-.1-.4-.29-.37-.46s.08-.44.12-.65A2.1,2.1,0,0,0,24,15.66h.25l.52.1.34.09.1.16-.09.21a3.81,3.81,0,0,1-.17,1.25,1.87,1.87,0,0,0,0,.34c0,.25,0,.42.33.49a3.63,3.63,0,0,1,.79.39.76.76,0,0,1,.42.9,4.45,4.45,0,0,1-.56,1.26c-.4.63-.83,1.23-1.25,1.84a7,7,0,0,1-.49.59c-.3.34-.62.68-.92,1s-.5.59-.78.85c-.43.41-.89.79-1.34,1.18s-.79.7-1.2,1a15.2,15.2,0,0,1-3.52,2.23,3.18,3.18,0,0,1-1.46.32,6.79,6.79,0,0,1-.79-2.18C14.1,27.56,14.05,27.4,14,27.23Z"
    />
    <path
      className="cls-6"
      d="M64.64,1.53a2.65,2.65,0,0,1-.36,1.41,12,12,0,0,1-.75,1.31c-.34.5-.73,1-1.1,1.44A31.22,31.22,0,0,1,60,8.35,22.63,22.63,0,0,1,58,10c-.54.42-1.08.85-1.66,1.22a9,9,0,0,1-2.46,1.27c-.22.06-.46.09-.69.14l-.28.09a4.76,4.76,0,0,1-.75-1.47l.79-.26a8.7,8.7,0,0,0,2.28-1.14,14.67,14.67,0,0,0,1.67-1.23c.42-.37.91-.68,1.3-1.07A34.77,34.77,0,0,0,60.8,4.91c.43-.53.88-1.06,1.27-1.63a4.82,4.82,0,0,0,1-2.32l.2-.2A5.31,5.31,0,0,1,64.64,1.53Z"
    />
    <path
      className="cls-7"
      d="M59.21,0a5.78,5.78,0,0,1,2.15.13l-.07.15A4.49,4.49,0,0,1,60.56,2c-.39.6-.8,1.18-1.24,1.75S58.45,4.78,58,5.3a14.16,14.16,0,0,1-2.58,2.11C54.6,8,53.75,8.47,52.91,9a3.13,3.13,0,0,1-1.23.53L51.62,9a6,6,0,0,1,0-1.59,1.22,1.22,0,0,0,.9-.27c.27-.2.55-.39.83-.58a3.62,3.62,0,0,0,1.32-1.2.49.49,0,0,1,.4-.18,1.6,1.6,0,0,0,1.26-.5,1.36,1.36,0,0,0,.54-.91,1.25,1.25,0,0,1,.37-.83A15.31,15.31,0,0,0,58.83,1,1.24,1.24,0,0,0,59.21,0Z"
    />
    <path
      className="cls-8"
      d="M47.83,24.57a1,1,0,0,1-.25.7c-.12.19-.25.37-.38.56a.6.6,0,0,0,.07.87,2.28,2.28,0,0,1,.6,1l0,.21A2.75,2.75,0,0,1,47.54,29a2.2,2.2,0,0,1-1.19.86,1.57,1.57,0,0,1-1.63-.35c-.5-.49-.63-.48-1.25-.14-.2.11-.39.23-.59.33s-.31,0-.29-.14c0-.38-.26-.38-.47-.43-.86-.21-1.73-.4-2.6-.59l-2.9-.63c-.8-.19-1.58-.43-2.39-.59-1.13-.22-2.25-.51-3.37-.77s-2.23-.47-3.33-.77a1.26,1.26,0,0,0-.39,0c.09-.17.18-.34.26-.52l1.11.21,2.57.57,1.86.43,2.15.52c1,.22,1.92.47,2.88.67s1.74.4,2.6.6c.64.15,1.28.28,1.91.44a.42.42,0,0,0,.51-.21,6.35,6.35,0,0,1,1-1.42l.06-.23.25-.13a8.51,8.51,0,0,1,1.6-1.27A6.86,6.86,0,0,1,47.83,24.57Zm-2.59,4.11c-.09.27.12.44.31.62a.63.63,0,0,0,.63.15,1.65,1.65,0,0,0,1.18-1,.49.49,0,0,0,0-.29,1.35,1.35,0,0,0-.33-.56,1.26,1.26,0,0,0-.74-.22A1.31,1.31,0,0,0,45.24,28.68Z"
    />
    <path
      className="cls-9"
      d="M59.21,0a1.24,1.24,0,0,1-.38,1,15.31,15.31,0,0,1-1.57,1.94,1.25,1.25,0,0,0-.37.83,1.36,1.36,0,0,1-.54.91,1.6,1.6,0,0,1-1.26.5.49.49,0,0,0-.4.18,3.62,3.62,0,0,1-1.32,1.2c-.28.19-.56.38-.83.58a1.22,1.22,0,0,1-.9.27,11.41,11.41,0,0,1,.19-1.17,8.5,8.5,0,0,1,1.78-3.37,8.39,8.39,0,0,1,2.5-2A8.08,8.08,0,0,1,58.79.08Z"
    />
    <path
      className="cls-10"
      d="M1.93,25.78a5.43,5.43,0,0,0,1.74-.29,2,2,0,0,0,.67-.33,2.76,2.76,0,0,1,1.27-.71c.18,0,.36-.25.5-.42s.24-.28.45-.25a.4.4,0,0,0,.34-.13,1.58,1.58,0,0,1,.33-.22.28.28,0,0,1,.17.38,4.05,4.05,0,0,0-.08,1.1,3.32,3.32,0,0,0,.44,1.82A2.59,2.59,0,0,0,9.43,28c.26.06.52.13.78.17a2.31,2.31,0,0,0,.37,0l.29-.05a2.44,2.44,0,0,0,.62-.11c.16-.09.23,0,.35,0a3.92,3.92,0,0,1-1.42.55,7.26,7.26,0,0,1-2.27.29,8.79,8.79,0,0,1-3.22-.74,8.5,8.5,0,0,1-2.21-1.44A11.15,11.15,0,0,1,1.93,25.78Z"
    />
    <path
      className="cls-11"
      d="M19,39.54h.55c.16.29-.07.46-.19.61-.31.42-.67.8-1,1.21s-.81,1-1.21,1.47a1.34,1.34,0,0,0-.12.24c0,.08.08.16.13.26h.17a2.6,2.6,0,0,1,1.14-.25,1.93,1.93,0,0,1,1.63,1,2.45,2.45,0,0,1-.19,2.66,2.24,2.24,0,0,1-1.1.59,2,2,0,0,1-.54.09,2,2,0,0,1-2.12-1.73,1.84,1.84,0,0,1,0-.74c0-.14.1-.37-.07-.48a.69.69,0,0,0-.61,0l-.15.08a8,8,0,0,1-.73.57,23.5,23.5,0,0,1-2.07,1.53c-.43.27-.85.56-1.3.79A4.77,4.77,0,0,1,9.44,48a.55.55,0,0,0-.4.18l-.35-.51c0-.14.15-.1.25-.11a3.83,3.83,0,0,0,.58-.07,6.84,6.84,0,0,0,1.87-.84,21.4,21.4,0,0,0,1.85-1.25c.51-.4,1-.83,1.5-1.24s.76-.6,1.1-.94.9-1,1.39-1.45,1.09-1.31,1.66-1.94A.72.72,0,0,0,19,39.54Zm-1.9,5.66a.54.54,0,0,0,.21.54,1.13,1.13,0,0,0,.69.36A1.15,1.15,0,0,0,19,45a1,1,0,0,0-.93-.91A1,1,0,0,0,17.06,45.2Z"
    />
    <path
      className="cls-10"
      d="M41.44,53.43l.16-.1.9-.18.32-.08a2.16,2.16,0,0,0,1.12-.55,1.46,1.46,0,0,1,1-.5.91.91,0,0,0,.63-.45c.14-.21.26-.35.53-.23s.24-.08.32-.18.09-.22.2-.15l.17.3a5.32,5.32,0,0,0-.07,1.32,3.26,3.26,0,0,0,.2,1.24,2.84,2.84,0,0,0,.55.84,2.6,2.6,0,0,0,2.13.94,2.18,2.18,0,0,0,.84-.07.57.57,0,0,1,.53.08,7.29,7.29,0,0,1-2.25.61c-.41,0-.82.07-1.23.07A8.36,8.36,0,0,1,42,54,6.35,6.35,0,0,1,41.44,53.43Z"
    />
    <path
      className="cls-10"
      d="M57.91,43.43c.05-.18.19-.19.34-.18A7.44,7.44,0,0,0,59.73,43a1.75,1.75,0,0,0,.63-.41l.53-.41a.47.47,0,0,1,.27-.1,1.06,1.06,0,0,0,.83-.5.53.53,0,0,1,.68-.21c.17-.06.23-.32.5-.21a1.86,1.86,0,0,1,0,.41,3.87,3.87,0,0,0,.4,2.73,2.54,2.54,0,0,0,1.81,1.31,3.53,3.53,0,0,0,1.55,0c.1,0,.31-.09.3.16a4.7,4.7,0,0,1-1.41.43A7.84,7.84,0,0,1,62,46.05,8.5,8.5,0,0,1,58.37,44,6.09,6.09,0,0,1,57.91,43.43Z"
    />
    <path
      className="cls-10"
      d="M9.21,48.35l.11-.1a5.36,5.36,0,0,0,1.12-.19l.11-.05c.57,0,.92-.37,1.32-.66a1.43,1.43,0,0,1,.75-.33.86.86,0,0,0,.65-.42c.15-.23.33-.4.62-.33s.33-.2.55-.3a1.88,1.88,0,0,1,0,.74,1.72,1.72,0,0,0,0,.9c0,.23,0,.47.08.71a2.7,2.7,0,0,0,1.33,1.91,2.3,2.3,0,0,0,1.15.34,5,5,0,0,0,.54.08c.29,0,.57-.11.86-.16.11,0,.27-.07.27.15a7.48,7.48,0,0,1-2.84.63c-.45,0-.9,0-1.35,0A7.21,7.21,0,0,1,13.39,51a8,8,0,0,1-2.86-1.38A5.18,5.18,0,0,1,9.21,48.35Z"
    />
    <path
      className="cls-10"
      d="M71.61,17.85c.11-.19.32-.13.48-.17a8.13,8.13,0,0,1,1-.21,2.3,2.3,0,0,0,.79-.38c.23-.14.44-.34.68-.49a.87.87,0,0,1,.39-.14,1,1,0,0,0,.66-.38c.12-.14.26-.27.38-.41a1,1,0,0,0,.42.08c.11,0,.21-.14.35-.25a2.8,2.8,0,0,1,0,1,1.44,1.44,0,0,0,0,.46c0,.29.12.57.16.86a2.47,2.47,0,0,0,.48,1.1A2.77,2.77,0,0,0,79,20.05a1.81,1.81,0,0,0,.47,0h.94c.13,0,.19,0,.18.17a9.42,9.42,0,0,1-2.24.48,7.36,7.36,0,0,1-1.56-.06,7.5,7.5,0,0,1-2.21-.61,8.23,8.23,0,0,1-2.45-1.62C71.93,18.25,71.78,18,71.61,17.85Z"
    />
    <path
      className="cls-11"
      d="M69.56,31.75a3.77,3.77,0,0,1-.67,2,18.1,18.1,0,0,1-1.5,2.09c-.58.67-1.14,1.37-1.71,2a.33.33,0,0,0-.08.36.26.26,0,0,0,.33.13,5.08,5.08,0,0,0,.51-.17,1.82,1.82,0,0,1,1.57.23,1.88,1.88,0,0,1,.89,1.46,2.06,2.06,0,0,1-.34,1.4,2.16,2.16,0,0,1-1.78,1,1.94,1.94,0,0,1-1.7-.91,1.48,1.48,0,0,1-.27-.91v-.77c0-.3,0-.33-.36-.34a.79.79,0,0,0-.55.17c-.41.34-.83.68-1.26,1-.66.48-1.33.94-2,1.4A6.86,6.86,0,0,1,58.2,43c-.2,0-.4,0-.59.07l-.24-.35a3.38,3.38,0,0,1,.54-.09,3.89,3.89,0,0,0,1.3-.42,4.9,4.9,0,0,0,.91-.53c.4-.25.81-.5,1.19-.78s1-.78,1.54-1.18,1-.8,1.47-1.22,1-1,1.53-1.53l.21-.22c.29-.33.57-.67.85-1s.39-.45.56-.69c.4-.58.81-1.15,1.17-1.75a3.38,3.38,0,0,0,.57-1.69l.1-.12ZM66.72,39a1.13,1.13,0,0,0-.17,2.24,1.25,1.25,0,0,0,1.27-1.2A1,1,0,0,0,66.72,39Z"
    />
    <path
      className="cls-11"
      d="M13.71,14.27c-.14.36-.09.76-.25,1.14a6.43,6.43,0,0,1-.79,1.43c-.21.3-.41.6-.64.89s-.45.58-.68.86L10.19,20c-.1.12-.23.22-.32.34a.54.54,0,0,0-.13.3c0,.25.14.36.39.28l.22-.12a1.91,1.91,0,0,1,1.41,0,2.06,2.06,0,0,1,1,.89A2.17,2.17,0,0,1,13,23a1.83,1.83,0,0,1-.8,1.34,2.41,2.41,0,0,1-1.33.45,1.78,1.78,0,0,1-1.27-.47A1.86,1.86,0,0,1,9,23.19a1.58,1.58,0,0,1,0-.78.5.5,0,0,0,0-.34l-.15-.18A1.11,1.11,0,0,0,8,22c-.48.38-.93.79-1.42,1.15s-1.22.9-1.86,1.3a6.78,6.78,0,0,1-2.1,1,2.78,2.78,0,0,1-.49.08c-.15,0-.29,0-.34.18l-.29-.43a1.28,1.28,0,0,1,.32-.09,3.08,3.08,0,0,0,1.4-.38c.6-.32,1.17-.68,1.74-1a7.54,7.54,0,0,0,.8-.55c.48-.37,1-.75,1.42-1.14s.94-.72,1.37-1.13,1-1,1.47-1.55,1.08-1.21,1.57-1.84a21.38,21.38,0,0,0,1.22-1.77,3.41,3.41,0,0,0,.41-1c.07-.25,0-.53.14-.78Zm-4,8.4v.25c0,.17,0,.37.2.49a4.48,4.48,0,0,0,.47.28.75.75,0,0,0,.57,0,1.43,1.43,0,0,0,.77-.56,1.07,1.07,0,0,0-.49-1.6A1.16,1.16,0,0,0,9.74,22.67Z"
    />
    <path
      className="cls-11"
      d="M83.27,6.15a1.27,1.27,0,0,0-.1.37,2.81,2.81,0,0,1-.52,1.53c-.39.61-.81,1.21-1.23,1.81a7.64,7.64,0,0,1-.51.62L79.45,12.2a.63.63,0,0,0-.14.61.79.79,0,0,0,.6-.06,1.85,1.85,0,0,1,2.4.87,1.7,1.7,0,0,1,.26.86,2.34,2.34,0,0,1-.95,1.82,1.61,1.61,0,0,1-1,.33,2.6,2.6,0,0,1-.92-.09A1.92,1.92,0,0,1,78.51,15a2.35,2.35,0,0,1,.09-1.09.71.71,0,0,0-1.06,0c-.65.53-1.32,1.05-2,1.55-.46.35-.94.67-1.41,1l-.18.1c-.45.21-.89.43-1.34.63a3.18,3.18,0,0,1-1.37.2l-.15-.24.15-.11a3.22,3.22,0,0,0,1.57-.43A11.41,11.41,0,0,0,75,15.32c.58-.43,1.14-.88,1.7-1.33.24-.19.47-.41.71-.61s.66-.53,1-.82.43-.52.68-.75c.64-.58,1.15-1.27,1.72-1.9a7.34,7.34,0,0,0,.56-.69A11.69,11.69,0,0,0,82.14,8a4.59,4.59,0,0,0,.77-2l.1-.11Zm-2.78,7.3a.87.87,0,0,0-.68.12,1.38,1.38,0,0,0-.54,1,1.08,1.08,0,0,0,1.42,1,1.48,1.48,0,0,0,.83-1,.74.74,0,0,0-.2-.7C81.07,13.65,80.88,13.32,80.49,13.45Z"
    />
    <path
      className="cls-11"
      d="M53.1,41.75a3.21,3.21,0,0,1-.53,1.75c-.22.36-.4.74-.63,1.09s-.47.6-.71.9-.68.86-1,1.28l-.93,1.11a.41.41,0,0,0-.15.33c0,.25.1.33.35.25a2.76,2.76,0,0,1,1.06-.22,1.78,1.78,0,0,1,1.61.94,1.92,1.92,0,0,1,0,2,2.12,2.12,0,0,1-1.55,1A2,2,0,0,1,49.34,52a1.52,1.52,0,0,1-.81-.84,2,2,0,0,1-.15-1.28,1.88,1.88,0,0,0,0-.33l-.11-.16a1.22,1.22,0,0,0-.82.07c-.41.33-.81.66-1.23,1a22.41,22.41,0,0,1-2.34,1.65,6,6,0,0,1-2.2.89l-.58.1-.24-.35a3.74,3.74,0,0,0,1-.17,6.76,6.76,0,0,0,1.45-.65,15.53,15.53,0,0,0,1.72-1.14c.58-.41,1.12-.86,1.67-1.3s1-.79,1.46-1.23a14.68,14.68,0,0,0,1.08-1.15c.41-.45.82-.91,1.21-1.38a15.4,15.4,0,0,0,1.75-2.48,3.28,3.28,0,0,0,.5-1.79H53C53,41.54,53.06,41.65,53.1,41.75Zm-3,9.51a1.25,1.25,0,0,0,1.23-1.15A1.12,1.12,0,0,0,50.27,49a1.22,1.22,0,0,0-1.15,1.16A1,1,0,0,0,50.13,51.26Z"
    />
    <path
      className="cls-10"
      d="M18.35,22.4a1.55,1.55,0,0,1-.73-.15A4.86,4.86,0,0,0,17,22a.74.74,0,0,0-.33,0c-.12,0-.16.13-.17.26l-.19.2a1.41,1.41,0,0,1-.06-.63,3.62,3.62,0,0,0-.35-1.49A2.88,2.88,0,0,0,14,18.81a1.31,1.31,0,0,0-.34,0H12.11a.48.48,0,0,1,.15-.59,2,2,0,0,0,.58-.68l.08-.24a1.66,1.66,0,0,1,.41-.9,2.71,2.71,0,0,0,.58-1.19c0-.15,0-.31.05-.46l.1-.16a7.16,7.16,0,0,1,1.53,2,6.2,6.2,0,0,1,.64,1.53c.09.4.16.81.24,1.22l.08.1h0l.08.44c0,.18,0,.33.25.35s.17.09.26.11c.27.07.49.27.79.23.05.12.12.24.17.37A3.17,3.17,0,0,1,18.35,22.4Z"
    />
    <path
      className="cls-12"
      d="M63.29.76l-.2.2a4.82,4.82,0,0,1-1,2.32c-.39.57-.84,1.1-1.27,1.63a34.77,34.77,0,0,1-2.61,2.68c-.39.39-.88.7-1.3,1.07a14.67,14.67,0,0,1-1.67,1.23A8.7,8.7,0,0,1,52.94,11l-.79.26c-.1-.32-.2-.63-.29-.94a1,1,0,0,0,.52-.13,4.49,4.49,0,0,1,.76-.38A6.36,6.36,0,0,0,54.69,9c.58-.4,1.13-.84,1.7-1.25a10,10,0,0,0,1.49-1.25c.62-.62,1.2-1.26,1.81-1.88a12.66,12.66,0,0,0,1.24-1.5,9.79,9.79,0,0,0,.93-1.55A3.61,3.61,0,0,0,62.22.36,3.37,3.37,0,0,1,63.29.76Z"
    />
    <path
      className="cls-11"
      d="M30.18,4.26a1,1,0,0,0-.1.36A2.19,2.19,0,0,1,29.7,5.9a24.83,24.83,0,0,1-1.49,2.19c-.36.49-.79.92-1.19,1.39a11.42,11.42,0,0,0-.77.95.45.45,0,0,0,.07.59c.31-.1.61-.21.91-.29a2.08,2.08,0,0,1,.5,0l.11,0c.51,0,.8.31,1.13.58a1.63,1.63,0,0,1,.48,1.09,1.9,1.9,0,0,1-.36,1.31,2.26,2.26,0,0,1-1.24.94.57.57,0,0,1-.57-.11,3,3,0,0,0-1.05-.71c-.15.77-.29,1.55-.38,2.33l-.26-.09c0-.28.05-.56.1-.84s.16-.82.24-1.23c.06-.25,0-.32-.29-.45l-.05-.06a1.46,1.46,0,0,0,0-.47.74.74,0,0,1,0-.53,5.76,5.76,0,0,0,.13-.89,1.81,1.81,0,0,0-1.24.3,11,11,0,0,1-1.63,1.16l-.16.09-.11,0a.89.89,0,0,1,.15-.29,8.18,8.18,0,0,1,.82-.74,19.38,19.38,0,0,0,1.94-1.67c.74-.74,1.41-1.53,2.11-2.3a8.38,8.38,0,0,0,.52-.66c.35-.52.7-1.05,1-1.58a2.89,2.89,0,0,0,.59-1.63L29.82,4C30,4,30.07,4.17,30.18,4.26Zm-3,9.44a1.17,1.17,0,0,0,1.19-1,.7.7,0,0,0,0-.31,2,2,0,0,0-.26-.53.73.73,0,0,0-.52-.34,1.13,1.13,0,0,0-1.38.88A1,1,0,0,0,27.16,13.7Z"
    />
    <path
      className="cls-10"
      d="M55.74,50.14c-.09,0-.16,0-.16-.15s0-.48,0-.73A3,3,0,0,0,54.63,47a2.24,2.24,0,0,0-1.47-.65c-.22,0-.44-.08-.66-.08a8,8,0,0,0-.84.08h-.18a.48.48,0,0,1,.1-.52,3.38,3.38,0,0,0,.6-.71c.15-.24.18-.56.31-.82a3.16,3.16,0,0,1,.44-.66,2,2,0,0,0,.42-1.25,2.54,2.54,0,0,1,0-.39,4.59,4.59,0,0,1,1,1.09,7.35,7.35,0,0,1,1,1.74,12.6,12.6,0,0,1,.38,1.37c0,.12.06.24.1.36a3.43,3.43,0,0,1,.07,1l.06.24a4.87,4.87,0,0,1,0,.54,3.2,3.2,0,0,0-.07.41A3.89,3.89,0,0,1,55.74,50.14Z"
    />
    <path
      className="cls-13"
      d="M62.22.36a3.61,3.61,0,0,1-.36,1.24,9.79,9.79,0,0,1-.93,1.55,12.66,12.66,0,0,1-1.24,1.5c-.61.62-1.19,1.26-1.81,1.88a10,10,0,0,1-1.49,1.25c-.57.41-1.12.85-1.7,1.25a6.36,6.36,0,0,1-1.55.81,4.49,4.49,0,0,0-.76.38,1,1,0,0,1-.52.13,2.37,2.37,0,0,1-.18-.86A3.13,3.13,0,0,0,52.91,9c.84-.49,1.69-1,2.5-1.55A14.16,14.16,0,0,0,58,5.3c.46-.52.91-1,1.33-1.59S60.17,2.56,60.56,2A4.49,4.49,0,0,0,61.29.32l.07-.15Z"
    />
    <path
      className="cls-10"
      d="M72.21,40.06c-.09,0-.17,0-.16-.14,0-.28,0-.57,0-.86a2.9,2.9,0,0,0-.91-2.1,2.77,2.77,0,0,0-1.9-.77,2.26,2.26,0,0,0-.93.07.29.29,0,0,1-.39-.15,1.75,1.75,0,0,1,.55-.73.93.93,0,0,0,.3-.54,4,4,0,0,1,.53-1.08,3.28,3.28,0,0,1,.2-.31,1.41,1.41,0,0,0,.32-1c0-.16,0-.32,0-.47l.21.13A7.69,7.69,0,0,1,72,35.21a9.51,9.51,0,0,1,.36,1.49,8.76,8.76,0,0,0,.08,1,5.86,5.86,0,0,1,0,1.11,5.77,5.77,0,0,1-.12,1.09Z"
    />
    <path
      className="cls-14"
      d="M39.64,46.09c.15-.43.29-.86.43-1.29a1,1,0,0,0,.71-.22c.43-.33.9-.62,1.35-.94a7,7,0,0,0,1.29-1.2c.35-.42.69-.86,1-1.29a4.77,4.77,0,0,0,.36-.62c0-.07.05-.18-.07-.21,0-.25.05-.5.08-.75s.26-.24.17-.45l.21-.26A2.83,2.83,0,0,1,47,37.73a8.57,8.57,0,0,1-.26,2.07,1.36,1.36,0,0,1-.32.08c-.27,0-.33.12-.38.35a1.35,1.35,0,0,1-.2.5c-.21.3-.45.58-.68.86a2.23,2.23,0,0,0-.48.83c-.09.31-.09.64-.19.94a6.19,6.19,0,0,1-.45.89l-.17.11c-.29.1-.58.18-.88.27a4,4,0,0,0-1.3.42l-1,.68A1.52,1.52,0,0,1,39.64,46.09Z"
    />
    <path
      className="cls-10"
      d="M23.52,45.14l-.16-.09a1,1,0,0,1,0-.35,3.39,3.39,0,0,0-.21-1.41,2.52,2.52,0,0,0-1.51-1.6,3.53,3.53,0,0,0-1.57-.25c-.32,0-.64.08-1,.12a.61.61,0,0,1,.13-.69,6.48,6.48,0,0,0,.87-1.14.59.59,0,0,1,.51-.26,1.72,1.72,0,0,0,.82-.41c.14-.1.19-.33.28-.51l.35-.65A7.43,7.43,0,0,1,23.28,40a8.66,8.66,0,0,1,.35,1.42,9,9,0,0,0,.09.92,4,4,0,0,1,.06,1.38A12.9,12.9,0,0,1,23.52,45.14Z"
    />
    <path
      className="cls-10"
      d="M86,14c-.24,0-.26-.23-.25-.42a3.05,3.05,0,0,0-.15-1,2.53,2.53,0,0,0-1.33-1.66,3.5,3.5,0,0,0-1.88-.37,4.44,4.44,0,0,0-.67.11A.81.81,0,0,1,82,9.94a1.88,1.88,0,0,0,.6-1.08A1.65,1.65,0,0,1,83,8.17a2,2,0,0,0,.54-1.38c0-.11-.09-.26.09-.33a7,7,0,0,1,1.53,1.93,7.29,7.29,0,0,1,.43.89c.18.46.24,1,.38,1.44a11.45,11.45,0,0,1,.16,2.61A2.3,2.3,0,0,1,86,14Z"
    />
    <path
      className="cls-10"
      d="M32.91,12c-.13,0-.15-.06-.17-.18a4.18,4.18,0,0,0-.43-1.74,2.92,2.92,0,0,0-1.76-1.37,2.59,2.59,0,0,0-.67-.07H28.63a.54.54,0,0,1,.17-.49c.11-.13.25-.24.36-.37a1.55,1.55,0,0,0,.32-.67,2.18,2.18,0,0,1,.38-.78A1.73,1.73,0,0,0,30.34,5c0-.18-.08-.39.17-.47.29.31.61.6.87.93a6.05,6.05,0,0,1,.95,1.63,7.29,7.29,0,0,1,.51,1.8c0,.29.14.56.17.85s0,.6,0,.9,0,.56,0,.84A2.72,2.72,0,0,1,32.91,12Z"
    />
    <path
      className="cls-15"
      d="M11.9,13.08l.69.36-.09.2a6.17,6.17,0,0,1-.11.62,4.51,4.51,0,0,1-.48,1c-.31.47-.58,1-.91,1.41s-.67.81-1,1.2S9.08,19,8.59,19.44s-1,.94-1.55,1.39-.81.72-1.23,1.05A16.15,16.15,0,0,1,2.55,24a5,5,0,0,1-1.08.33.66.66,0,0,0-.44.17C.94,24.3.85,24.13.77,24a2.91,2.91,0,0,1,.35-.18c.31-.11.65-.18.95-.31a13.7,13.7,0,0,0,2.84-1.72c.52-.41,1-.86,1.54-1.27a13.15,13.15,0,0,0,1.43-1.26L9.2,17.81c.23-.25.45-.5.67-.76s.32-.4.47-.6a12.09,12.09,0,0,0,.94-1.39A3.7,3.7,0,0,0,11.9,13.08Z"
    />
    <path
      className="cls-16"
      d="M39.54,49.2c-.15-.25,0-.52-.07-.78a1.69,1.69,0,0,0,1.1-.36c.87-.55,1.75-1.1,2.64-1.63a8.66,8.66,0,0,0,2.55-2.19c.2-.28.38-.56.58-.83.42-.6.86-1.18,1.26-1.79.28-.44.51-.9.77-1.35a.45.45,0,0,0,0-.52.48.48,0,0,1-.08-.31,3.2,3.2,0,0,1,.09-.62,4.05,4.05,0,0,0,.16-1.25H49c0,.22-.05.45-.09.67s-.12.45-.16.68-.07.57-.1.88H49l.17.09a1.7,1.7,0,0,1-.27,1,12.13,12.13,0,0,1-1.83,2.72c-.31.38-.65.75-1,1.14a8,8,0,0,1-1.62,1.56c-.38.27-.74.56-1.12.82a17.94,17.94,0,0,1-2.05,1.33,4.51,4.51,0,0,0-.81.44A1.44,1.44,0,0,1,39.54,49.2Z"
    />
    <path
      className="cls-7"
      d="M64.54,8.87a2.43,2.43,0,0,1,.33-1.14,4.47,4.47,0,0,1,.87-1.39,4.74,4.74,0,0,1,.64-.53,1,1,0,0,1,.81-.09c.15,0,.29.09.44.13l.13-.09a3.12,3.12,0,0,0,.42.18c.3.08.61.12.91.2a1.59,1.59,0,0,1,.29.15l-.12.09L69,6.52l-.15.06a5,5,0,0,0-1,1.24,3.74,3.74,0,0,0-.3.77.79.79,0,0,0-.18.55l-.16.25c-.35-.07-.69-.16-1-.22A7.66,7.66,0,0,0,64.54,8.87Z"
    />
    <path
      className="cls-17"
      d="M17.75,14.92l-.36-.61.41-.1a7.53,7.53,0,0,0,2.45-1.06c.51-.35,1-.7,1.52-1.06A22.54,22.54,0,0,0,24,10.22a26.79,26.79,0,0,0,3.37-3.69,10.76,10.76,0,0,0,1.28-2.17,1.68,1.68,0,0,0,.11-.4c0-.2.06-.41.1-.62l.53.35-.11.2c0,.18,0,.36-.07.54a4.86,4.86,0,0,1-.8,1.65c-.33.49-.71.94-1.07,1.42a16.64,16.64,0,0,1-1.61,1.86c-.64.64-1.3,1.28-2,1.89s-1.11.9-1.68,1.34a9.13,9.13,0,0,1-1.88,1.27l-.15.08a5.22,5.22,0,0,1-1.82.72C18.07,14.69,17.82,14.67,17.75,14.92Z"
    />
    <path
      className="cls-18"
      d="M40.08,51.26c-.07-.2-.13-.4-.19-.6a1.82,1.82,0,0,0,.74-.26,9.77,9.77,0,0,0,1.71-.78,23.43,23.43,0,0,0,2.8-2,20.45,20.45,0,0,0,1.59-1.5c.6-.59,1.17-1.21,1.74-1.83a8,8,0,0,0,.69-.85c.28-.41.55-.82.81-1.24a3.34,3.34,0,0,0,.62-1.67l.08-.19.18.08a.47.47,0,0,1,.43.18,1,1,0,0,0-.18.59l-.05.2a4.52,4.52,0,0,1-.74,1.49c-.56.77-1.12,1.55-1.75,2.27-.47.53-1,1-1.49,1.51a22,22,0,0,1-2.23,2,22.37,22.37,0,0,1-2.26,1.59A14.76,14.76,0,0,1,40.08,51.26Z"
    />
    <path
      className="cls-19"
      d="M83,5.94l-.1.11a4.59,4.59,0,0,1-.77,2,11.69,11.69,0,0,1-.79,1.19,7.34,7.34,0,0,1-.56.69c-.57.63-1.08,1.32-1.72,1.9-.25.23-.43.52-.68.75s-.65.55-1,.82-.47.42-.71.61c-.56.45-1.12.9-1.7,1.33a11.41,11.41,0,0,1-2.2,1.31,3.22,3.22,0,0,1-1.57.43l-.15.11-.36-.54a4.19,4.19,0,0,0,1-.17,6,6,0,0,0,1.22-.53,14.27,14.27,0,0,0,2-1.27c.46-.35.9-.73,1.36-1.08s1.07-.92,1.6-1.39a18.59,18.59,0,0,0,2.18-2.34c.5-.63,1-1.28,1.46-1.91a5.87,5.87,0,0,0,.66-1.2,2.38,2.38,0,0,0,.2-1.24Z"
    />
    <path
      className="cls-20"
      d="M28.1,3c.25,0,.41.18.62.25l-.11.16a4.19,4.19,0,0,1-.74,1.9c-.55.84-1.16,1.63-1.79,2.41a17.84,17.84,0,0,1-1.46,1.55c-.49.48-1,.9-1.52,1.37A20.15,20.15,0,0,1,21,12.33a9.7,9.7,0,0,1-3.17,1.61,4.78,4.78,0,0,0-.48.19,1,1,0,0,1-.27-.6c.14,0,.28,0,.41-.09a13.55,13.55,0,0,0,1.6-.62c.61-.32,1.18-.74,1.77-1.12a7.52,7.52,0,0,0,.64-.47l1.26-1.08c.49-.43,1-.84,1.46-1.3s1-1.15,1.57-1.74a10.79,10.79,0,0,0,.86-1A10.92,10.92,0,0,0,27.8,4.13a1.87,1.87,0,0,0,.21-.8A1.17,1.17,0,0,1,28.1,3Z"
    />
    <path
      className="cls-21"
      d="M7.93,12.24h.63a1.28,1.28,0,0,1,.32.09,1.61,1.61,0,0,1-.12.39c-.3.52-.61,1-.93,1.55S7,15.55,6.6,16.18a12,12,0,0,1-.81,1.07,6.09,6.09,0,0,1-1.9,1.4,9.66,9.66,0,0,0-1,.56c-.61.38-1.19.8-1.8,1.17A2,2,0,0,1,0,20.77c0-.27,0-.53,0-.79l.08-.17a.83.83,0,0,0,.6-.13c.4-.25.76-.53,1.15-.79A5.31,5.31,0,0,1,3,18.23c.45-.13.85-.35,1.29-.49a2.85,2.85,0,0,0,1-.74A3.3,3.3,0,0,0,6,15.69a7,7,0,0,1,.8-1.51c.26-.41.56-.8.85-1.2A1.51,1.51,0,0,0,7.93,12.24Z"
    />
    <path
      className="cls-19"
      d="M69.31,31.52l-.1.12a3.38,3.38,0,0,1-.57,1.69c-.36.6-.77,1.17-1.17,1.75-.17.24-.37.46-.56.69s-.56.67-.85,1l-.21.22c-.51.51-1,1-1.53,1.53s-1,.82-1.47,1.22-1,.8-1.54,1.18-.79.53-1.19.78a4.9,4.9,0,0,1-.91.53,3.89,3.89,0,0,1-1.3.42,3.38,3.38,0,0,0-.54.09l-.23-.35c0-.15.16-.16.3-.18a4.44,4.44,0,0,0,.89-.21c.45-.19.88-.42,1.31-.65a19,19,0,0,0,2.76-1.94c.3-.24.57-.52.86-.77s.76-.63,1.12-1,.51-.57.78-.84a14.68,14.68,0,0,0,1.54-1.71c.3-.43.66-.81,1-1.24a10.66,10.66,0,0,0,.65-1.08,2.58,2.58,0,0,0,.38-1.36c0-.09.06-.19.09-.28Z"
    />
    <path
      className="cls-15"
      d="M81.53,5.07c.25,0,.41.18.61.27a.26.26,0,0,0-.19.29,1,1,0,0,1,0,.43A7.66,7.66,0,0,1,81,7.88a17.39,17.39,0,0,1-1.3,1.67c-.46.46-.85,1-1.31,1.45s-.94.91-1.42,1.35c-.29.27-.58.55-.89.8s-.75.6-1.12.9a14.22,14.22,0,0,1-1.78,1.2,6.23,6.23,0,0,1-2,.88,4.5,4.5,0,0,0-.6.17.88.88,0,0,1-.25-.53,10.88,10.88,0,0,0,1.12-.3,11.17,11.17,0,0,0,2.81-1.6c.7-.51,1.37-1.08,2-1.64s1.06-.94,1.56-1.44.81-.94,1.24-1.39a14.51,14.51,0,0,0,1.34-1.7,6.42,6.42,0,0,0,1-1.81A7,7,0,0,0,81.53,5.07Z"
    />
    <path
      className="cls-19"
      d="M13.38,14c-.09.25-.07.53-.14.78a3.41,3.41,0,0,1-.41,1,21.38,21.38,0,0,1-1.22,1.77c-.49.63-1,1.24-1.57,1.84s-1,1.06-1.47,1.55-.91.76-1.37,1.13-.94.77-1.42,1.14a7.54,7.54,0,0,1-.8.55c-.57.35-1.14.71-1.74,1a3.08,3.08,0,0,1-1.4.38,1.28,1.28,0,0,0-.32.09l-.23-.34a.27.27,0,0,1,.25-.19,2.5,2.5,0,0,0,.73-.16q.9-.4,1.77-.87A17.28,17.28,0,0,0,6.7,21.77c.3-.24.57-.51.86-.77s.89-.75,1.3-1.16.78-.9,1.2-1.32a16.29,16.29,0,0,0,1.08-1.26A12.42,12.42,0,0,0,12.59,15a4.16,4.16,0,0,0,.35-1.33Z"
    />
    <path
      className="cls-15"
      d="M40.47,52.05c-.08-.18-.17-.35-.25-.52l.92-.25A8.86,8.86,0,0,0,43.57,50c.53-.35,1-.79,1.51-1.15a27.21,27.21,0,0,0,2.16-1.84c.39-.34.69-.76,1.06-1.12a15.07,15.07,0,0,0,1.29-1.47,18.24,18.24,0,0,0,1.14-1.52,4.7,4.7,0,0,0,.8-2.27l.44.25c0,.24,0,.48-.07.72a3.57,3.57,0,0,1-.55,1.3,11.77,11.77,0,0,1-1.26,1.79l-.67.77c-.31.36-.6.72-.91,1.07s-.64.66-1,1-.63.58-1,.86-.58.48-.88.72-.44.4-.68.57c-.6.42-1.19.85-1.82,1.23a5.88,5.88,0,0,1-1.61.79A11.1,11.1,0,0,0,40.47,52.05Z"
    />
    <path
      className="cls-19"
      d="M52.74,41.44a3.28,3.28,0,0,1-.5,1.79,15.4,15.4,0,0,1-1.75,2.48c-.39.47-.8.93-1.21,1.38a14.68,14.68,0,0,1-1.08,1.15c-.46.44-1,.83-1.46,1.23s-1.09.89-1.67,1.3a15.53,15.53,0,0,1-1.72,1.14,6.76,6.76,0,0,1-1.45.65,3.74,3.74,0,0,1-1,.17l-.24-.35.13-.08a5.29,5.29,0,0,0,1.56-.46A16.42,16.42,0,0,0,45.26,50a18.32,18.32,0,0,0,1.92-1.58c.94-.9,1.82-1.86,2.72-2.79a6.73,6.73,0,0,0,.6-.72L51.78,43a3.13,3.13,0,0,0,.53-1.78C52.53,41.15,52.57,41.4,52.74,41.44Z"
    />
    <path
      className="cls-22"
      d="M16.79,12.75c-.15-.14,0-.34-.1-.51a1.07,1.07,0,0,0,.32-.08c.3-.13.58-.29.87-.42A12.07,12.07,0,0,0,20,10.51c.41-.32.86-.59,1.26-.92.61-.51,1.2-1,1.77-1.6s.92-.93,1.35-1.43.74-.95,1.1-1.44A6,6,0,0,0,26.63,3c0-.18.06-.36.09-.54a1,1,0,0,1,.6.18,5.78,5.78,0,0,1-1.05,2.47c-.22.3-.44.59-.67.87a16.47,16.47,0,0,1-1.05,1.27,29,29,0,0,1-2.73,2.59L20.32,11A11.6,11.6,0,0,1,18,12.37,2.1,2.1,0,0,1,16.79,12.75Z"
    />
    <path
      className="cls-22"
      d="M10.34,12.5l.33.11.19.06a3.13,3.13,0,0,1-.45,1.47,12.24,12.24,0,0,1-1.37,2l-.62.7c-.42.48-.82,1-1.27,1.43s-.91.74-1.3,1.16-.81.59-1.15.95a3.69,3.69,0,0,1-.53.43,15.64,15.64,0,0,1-2.69,1.55,2.13,2.13,0,0,1-1.14.36c-.16-.15-.05-.35-.11-.52a.93.93,0,0,0,.29-.05c.68-.33,1.38-.63,2-1a21.47,21.47,0,0,0,1.88-1.28C5,19.43,5.6,19,6.15,18.44a25.51,25.51,0,0,0,2.07-2.21A12.12,12.12,0,0,0,10,13.6,3.35,3.35,0,0,0,10.34,12.5Z"
    />
    <path
      className="cls-19"
      d="M17.75,14.92c.07-.25.32-.23.49-.26a5.22,5.22,0,0,0,1.82-.72l.15-.08a9.13,9.13,0,0,0,1.88-1.27c.57-.44,1.15-.87,1.68-1.34s1.34-1.25,2-1.89A16.64,16.64,0,0,0,27.36,7.5c.36-.48.74-.93,1.07-1.42a4.86,4.86,0,0,0,.8-1.65c0-.18,0-.36.07-.54l.11-.2.22.11c.1.05.2.1.19.24l-.09.23a2.89,2.89,0,0,1-.59,1.63c-.34.53-.69,1.06-1,1.58a8.38,8.38,0,0,1-.52.66c-.7.77-1.37,1.56-2.11,2.3a19.38,19.38,0,0,1-1.94,1.67,8.18,8.18,0,0,0-.82.74.89.89,0,0,0-.15.29l-.32.11a6.86,6.86,0,0,0-.78.2,2.93,2.93,0,0,0-1.54,1.28,1.59,1.59,0,0,0-.64.13,1.77,1.77,0,0,1-1,.22.27.27,0,0,0-.33.2Z"
    />
    <path
      className="cls-8"
      d="M17.16,19.52a9.78,9.78,0,0,1,2.1-1.21c.1-.42.21-.83.31-1.24l.26-.33a3.09,3.09,0,0,1,2.23-1.07l.15.23c-.05.33-.1.64-.15,1s0,.42-.09.62a.55.55,0,0,1-.55.36,1.25,1.25,0,0,0-1.1.42c-.29.41-.52.86-.79,1.28a.63.63,0,0,0,0,.77.61.61,0,0,1,.16.46,6.1,6.1,0,0,0-.6-.38c-.34-.15-.7-.25-1.05-.4a1,1,0,0,0-.6-.16C17.37,19.72,17.15,19.74,17.16,19.52Z"
    />
    <path
      className="cls-15"
      d="M57,42.07l-.27-.54a2.22,2.22,0,0,1,.47-.17,5,5,0,0,0,1.58-.55,8.29,8.29,0,0,0,.92-.58c.45-.29.91-.56,1.33-.88s.79-.69,1.2-1a15.65,15.65,0,0,0,1.71-1.54L65.63,35a5.07,5.07,0,0,0,.52-.61c.41-.59.79-1.19,1.2-1.78a3,3,0,0,0,.56-1.9c.2,0,.38.06.44.28s.06.27,0,.44a5.79,5.79,0,0,1-.9,2c-.36.53-.8,1-1.15,1.52a22.58,22.58,0,0,1-1.71,1.94,14.27,14.27,0,0,1-1.86,1.7c-.42.35-.82.74-1.26,1.07-.66.48-1.34.91-2,1.36a5.79,5.79,0,0,1-2.27.92C57.11,41.89,57,42,57,42.07Z"
    />
    <path
      className="cls-22"
      d="M39.74,50.31c0-.2-.07-.4-.11-.6a1.13,1.13,0,0,0,.69-.17c.51-.28,1-.52,1.55-.82s1-.65,1.51-1c.34-.24.63-.52,1-.76a10.23,10.23,0,0,0,1.39-1.15c.3-.31.62-.61.91-.92a9.63,9.63,0,0,0,.73-.83c.29-.34.54-.72.83-1.06a8,8,0,0,0,1.3-2.19,1.46,1.46,0,0,0,.22-.85l.35.09.17.09a1.62,1.62,0,0,1,0,.38A7.48,7.48,0,0,1,49,43c-.43.61-1,1.14-1.43,1.73a7.33,7.33,0,0,1-.57.55,13.52,13.52,0,0,1-1,1.1Q45,47.23,44,48a18.27,18.27,0,0,1-1.87,1.29A9.35,9.35,0,0,1,39.74,50.31Z"
    />
    <path
      className="cls-23"
      d="M22.84,2.5l.59-.17A4.51,4.51,0,0,1,24,2.25a1.3,1.3,0,0,1-.09.4,11,11,0,0,1-.93,1.4,4.19,4.19,0,0,0-.63,1.13,4.52,4.52,0,0,1-.68,1.56,2.6,2.6,0,0,1-1.47.93,4.11,4.11,0,0,0-1.47.56l-1.32.9a1.13,1.13,0,0,1-.82.26,3.14,3.14,0,0,1,.25-1.12A1.14,1.14,0,0,0,17.63,8a8.08,8.08,0,0,1,1.45-.92,1.46,1.46,0,0,1,.63-.18,1.87,1.87,0,0,0,1.07-.34,1,1,0,0,0,.5-.81,2.43,2.43,0,0,1,.48-1.45c.25-.35.52-.7.78-1A1.06,1.06,0,0,0,22.84,2.5Z"
    />
    <path
      className="cls-24"
      d="M78.17,4.14c.24.15.51.05.77.09a1.56,1.56,0,0,1-.3.77c-.22.41-.41.83-.66,1.22s-.63.83-.93,1.26a10.59,10.59,0,0,1-2,2.39c-.24.19-.48.4-.73.57-1,.66-2,1.31-3,2-.38.24-.77.46-1.17.67a1.87,1.87,0,0,1-.48.11l0-.08c0-.15,0-.29-.07-.44h0l1-.54.13-.11c.78-.47,1.5-1,2.33-1.43a6.8,6.8,0,0,0,1.3-.76,6.08,6.08,0,0,0,1.61-1.78c.24-.41.48-.82.73-1.23.4-.61.83-1.2,1.21-1.82A1.34,1.34,0,0,0,78.17,4.14Z"
    />
    <path
      className="cls-20"
      d="M67.91,30.68a3,3,0,0,1-.56,1.9c-.41.59-.79,1.19-1.2,1.78a5.07,5.07,0,0,1-.52.61L63.89,36.8a15.65,15.65,0,0,1-1.71,1.54c-.41.32-.78.7-1.2,1s-.88.59-1.33.88a8.29,8.29,0,0,1-.92.58,5,5,0,0,1-1.58.55,2.22,2.22,0,0,0-.47.17l-.15-.34c.12-.2.35-.21.54-.26a7.22,7.22,0,0,0,1.53-.62l.09-.16a2.53,2.53,0,0,0,1.21-.43l.24,0a5.41,5.41,0,0,0,1.57-1.76l.08-.09.08-.09a1.72,1.72,0,0,0,.41-.19c.5-.46,1-.92,1.48-1.39.28-.28.53-.59.8-.88s.69-.73,1-1.11a7.25,7.25,0,0,0,.54-.7c.24-.35.45-.72.68-1.07a3.27,3.27,0,0,0,.61-1.59c0-.11.12-.21.18-.31Z"
    />
    <path
      className="cls-21"
      d="M78.17,4.14a1.34,1.34,0,0,1-.32.86c-.38.62-.81,1.21-1.21,1.82-.25.41-.49.82-.73,1.23A6.08,6.08,0,0,1,74.3,9.83a6.8,6.8,0,0,1-1.3.76c-.83.4-1.55,1-2.33,1.43l-.13.11-1,.54c0-.17,0-.35,0-.52l.07-.14a3.62,3.62,0,0,0,0-.47.88.88,0,0,0,.61-.13l1.19-.77a4.64,4.64,0,0,1,1.24-.71l.88-.26a2.57,2.57,0,0,0,1.26-.88,3.86,3.86,0,0,0,.69-1.28A3.79,3.79,0,0,1,76.32,6c.31-.35.54-.76.81-1.13a1,1,0,0,0,.18-.68Z"
    />
    <path
      className="cls-17"
      d="M70.55,16.3a4.5,4.5,0,0,1,.6-.17,6.23,6.23,0,0,0,2-.88A14.22,14.22,0,0,0,75,14.05c.37-.3.75-.59,1.12-.9s.6-.53.89-.8c.48-.44,1-.88,1.42-1.35s.85-1,1.31-1.45A17.39,17.39,0,0,0,81,7.88a7.66,7.66,0,0,0,.94-1.82,1,1,0,0,0,0-.43.26.26,0,0,1,.19-.29l.25.16a2.38,2.38,0,0,1-.2,1.24,5.87,5.87,0,0,1-.66,1.2c-.5.63-1,1.28-1.46,1.91a18.59,18.59,0,0,1-2.18,2.34c-.53.47-1,1-1.6,1.39s-.9.73-1.36,1.08a14.27,14.27,0,0,1-2,1.27,6,6,0,0,1-1.22.53,4.19,4.19,0,0,1-1,.17Z"
    />
    <path
      className="cls-16"
      d="M69.63,13.18a1.87,1.87,0,0,0,.48-.11c.4-.21.79-.43,1.17-.67,1-.65,2-1.3,3-2,.25-.17.49-.38.73-.57a10.59,10.59,0,0,0,2-2.39c.3-.43.65-.82.93-1.26s.44-.81.66-1.22a1.56,1.56,0,0,0,.3-.77l.6.08v.22a9.2,9.2,0,0,1-1,1.91,13.67,13.67,0,0,1-1,1.33c-.47.51-.88,1.07-1.35,1.58a15.41,15.41,0,0,1-2.44,2.11,18.89,18.89,0,0,1-2.69,1.72c-.13.06-.24.15-.37.2-.34.15-.63.43-1.05.41C69.69,13.59,69.66,13.38,69.63,13.18Z"
    />
    <path
      className="cls-17"
      d="M12.94,13.67A4.16,4.16,0,0,1,12.59,15a12.42,12.42,0,0,1-1.45,2.26,16.29,16.29,0,0,1-1.08,1.26c-.42.42-.78.9-1.2,1.32S8,20.62,7.56,21s-.56.53-.86.77A17.28,17.28,0,0,1,4,23.69q-.87.46-1.77.87a2.5,2.5,0,0,1-.73.16.27.27,0,0,0-.25.19L1,24.47a.66.66,0,0,1,.44-.17A5,5,0,0,0,2.55,24a16.15,16.15,0,0,0,3.26-2.09c.42-.33.82-.69,1.23-1.05s1.06-.9,1.55-1.39.93-1,1.39-1.55.71-.78,1-1.2.6-.94.91-1.41a4.51,4.51,0,0,0,.48-1,6.17,6.17,0,0,0,.11-.62l.09-.2Z"
    />
    <path
      className="cls-18"
      d="M70.14,15.43l-.09-.33.12-.11a10.77,10.77,0,0,0,2.93-1.43l.41-.29c.4-.29.8-.58,1.18-.89s.49-.42.74-.63.68-.52,1-.82C77,10.3,77.65,9.67,78.24,9a22.27,22.27,0,0,0,1.43-1.78,5.26,5.26,0,0,0,1-2l.12-.57.54.23-.1.11c-.07.22-.12.43-.17.65a6.35,6.35,0,0,1-1.12,2.1,18,18,0,0,1-1.67,2c-.76.8-1.55,1.57-2.38,2.29A23,23,0,0,1,74,13.51,13,13,0,0,1,71.3,15.1,3.94,3.94,0,0,1,70.14,15.43Z"
    />
    <path
      className="cls-25"
      d="M.34,22.75a2.13,2.13,0,0,0,1.14-.36,15.64,15.64,0,0,0,2.69-1.55,3.69,3.69,0,0,0,.53-.43c.34-.36.8-.58,1.15-.95s.89-.75,1.3-1.16.85-.95,1.27-1.43l.62-.7a12.24,12.24,0,0,0,1.37-2,3.13,3.13,0,0,0,.45-1.47c.19-.05.3.09.43.18a3.49,3.49,0,0,1-.31,1.3,11.65,11.65,0,0,1-1.07,1.71,14.9,14.9,0,0,1-1.44,1.72c-.6.65-1.24,1.26-1.87,1.88l-.21.21c-.56.45-1.14.89-1.69,1.35a11.72,11.72,0,0,1-3.09,1.86,2.34,2.34,0,0,1-1.13.31C.43,23,.39,22.89.34,22.75Z"
    />
    <path
      className="cls-14"
      d="M22.84,2.5a1.06,1.06,0,0,1-.3.77c-.26.34-.53.69-.78,1a2.43,2.43,0,0,0-.48,1.45,1,1,0,0,1-.5.81,1.87,1.87,0,0,1-1.07.34,1.46,1.46,0,0,0-.63.18A8.08,8.08,0,0,0,17.63,8a1.14,1.14,0,0,1-.83.26,2.52,2.52,0,0,1,.43-1.12A.8.8,0,0,0,17.79,7c.47-.34,1-.67,1.44-1A4.92,4.92,0,0,0,20,5.32c.23-.23.4-.51.61-.75s.63-.73.92-1.11a.9.9,0,0,0,.24-.62A2.57,2.57,0,0,1,22.84,2.5Z"
    />
    <path
      className="cls-26"
      d="M7.93,12.24a1.51,1.51,0,0,1-.27.74c-.29.4-.59.79-.85,1.2A7,7,0,0,0,6,15.69,3.3,3.3,0,0,1,5.26,17a2.85,2.85,0,0,1-1,.74c-.44.14-.84.36-1.29.49a5.31,5.31,0,0,0-1.12.66c-.39.26-.75.54-1.15.79a.83.83,0,0,1-.6.13l.1-.94a.92.92,0,0,0,.64-.16c.28-.19.58-.34.86-.53a4.25,4.25,0,0,1,1.68-.82L4,17.23a1.65,1.65,0,0,0,1.3-1.37,4.7,4.7,0,0,1,.35-1.2c.22-.41.54-.75.8-1.14A2.13,2.13,0,0,0,7,12.33Z"
    />
    <path
      className="cls-20"
      d="M16.9,39.11l.59.09v.13c-.35.41-.68.84-1.05,1.22a31.29,31.29,0,0,1-4.12,3.77,16.11,16.11,0,0,1-2.08,1.35,10.7,10.7,0,0,1-1.89.77.76.76,0,0,0-.25.19L7.81,46l.75-.27a9.76,9.76,0,0,0,2.32-1.18c.57-.39,1.12-.84,1.69-1.25a19.19,19.19,0,0,0,2.14-1.88l1-1,.62-.7Z"
    />
    <path
      className="cls-25"
      d="M16.79,12.75A2.1,2.1,0,0,0,18,12.37,11.6,11.6,0,0,0,20.32,11l1.5-1.16a29,29,0,0,0,2.73-2.59A16.47,16.47,0,0,0,25.6,6c.23-.28.45-.57.67-.87a5.78,5.78,0,0,0,1.05-2.47l.44.14c-.05.25-.09.5-.15.74a7.33,7.33,0,0,1-1.28,2.3c-.27.38-.58.72-.89,1.08s-.52.58-.79.86a9,9,0,0,0-1,1,8.86,8.86,0,0,1-1.13,1.08,10.76,10.76,0,0,1-1.28,1c-.46.34-.93.67-1.4,1a8.32,8.32,0,0,1-1.74.93,3.81,3.81,0,0,1-1.2.37Z"
    />
    <path
      className="cls-22"
      d="M79.89,4.4l.44.14a4.65,4.65,0,0,1-.76,2,20.51,20.51,0,0,1-2.16,2.76A24.24,24.24,0,0,1,75.61,11c-.63.56-1.3,1.09-2,1.62a10.85,10.85,0,0,1-1.77,1.13,11.86,11.86,0,0,1-2,.87c-.15-.12,0-.29-.09-.43.59-.26,1.2-.48,1.75-.79a15.56,15.56,0,0,0,1.85-1.17A22.28,22.28,0,0,0,76,10.09c.34-.37.75-.66,1.06-1.07a1.48,1.48,0,0,1,.29-.36c.33-.21.47-.56.74-.82a7.1,7.1,0,0,0,.71-1,6.19,6.19,0,0,0,1-1.88A5.78,5.78,0,0,0,79.89,4.4Z"
    />
    <path
      className="cls-27"
      d="M49.72,40a1.46,1.46,0,0,1-.22.85A8,8,0,0,1,48.2,43c-.29.34-.54.72-.83,1.06a9.63,9.63,0,0,1-.73.83c-.29.31-.61.61-.91.92A10.23,10.23,0,0,1,44.34,47c-.33.24-.62.52-1,.76-.49.34-1,.68-1.51,1s-1,.54-1.55.82a1.13,1.13,0,0,1-.69.17c-.15-.15,0-.35-.09-.51a1.44,1.44,0,0,0,1-.34,4.51,4.51,0,0,1,.81-.44,17.94,17.94,0,0,0,2.05-1.33c.38-.26.74-.55,1.12-.82a8,8,0,0,0,1.62-1.56c.3-.39.64-.76,1-1.14a12.13,12.13,0,0,0,1.83-2.72,1.7,1.7,0,0,0,.27-1h.09l.43.08Z"
    />
    <path
      className="cls-16"
      d="M25.85,2.32l.44.1a2.44,2.44,0,0,1-.11.62,9,9,0,0,1-.95,1.68c-.3.39-.56.81-.86,1.2s-.73.79-1,1.23a8,8,0,0,1-1.65,1.59,22.48,22.48,0,0,1-2.47,1.75c-.73.45-1.48.85-2.25,1.23a1.75,1.75,0,0,1-.41.09v-.52a5.67,5.67,0,0,0,.62-.23c.42-.23.83-.48,1.23-.73.7-.43,1.39-.84,2.08-1.29C21,8.7,21.49,8.35,22,8A5.89,5.89,0,0,0,22.84,7c.36-.45.68-.93,1-1.39a13.38,13.38,0,0,0,1.71-2.7C25.68,2.74,25.77,2.53,25.85,2.32Z"
    />
    <path
      className="cls-17"
      d="M57,42.07c.08-.07.16-.18.25-.19A5.79,5.79,0,0,0,59.47,41c.69-.45,1.37-.88,2-1.36.44-.33.84-.72,1.26-1.07a14.27,14.27,0,0,0,1.86-1.7,22.58,22.58,0,0,0,1.71-1.94c.35-.53.79-1,1.15-1.52a5.79,5.79,0,0,0,.9-2c0-.17-.11-.29,0-.44.16,0,.34,0,.43.2s-.1.19-.09.28a2.58,2.58,0,0,1-.38,1.36,10.66,10.66,0,0,1-.65,1.08c-.3.43-.66.81-1,1.24a14.68,14.68,0,0,1-1.54,1.71c-.27.27-.5.58-.78.84s-.75.65-1.12,1-.56.53-.86.77a19,19,0,0,1-2.76,1.94c-.43.23-.86.46-1.31.65a4.44,4.44,0,0,1-.89.21c-.14,0-.26,0-.3.18Z"
    />
    <path
      className="cls-8"
      d="M47,37.73a2.83,2.83,0,0,0-1.78,1.13l-.21.26c-.14-.07.08-.16,0-.24.06-.34.13-.68.19-1s.1-.67.18-1a1.28,1.28,0,0,1,.23-.45,3.28,3.28,0,0,1,2-1.2,6.31,6.31,0,0,1,.76-.07l.08.12c-.07.42-.15.84-.21,1.26s-.09.66-.13,1l-.17,0-.34.06Z"
    />
    <path
      className="cls-25"
      d="M69.88,14.65a11.86,11.86,0,0,0,2-.87,10.85,10.85,0,0,0,1.77-1.13c.66-.53,1.33-1.06,2-1.62a24.24,24.24,0,0,0,1.8-1.71,20.51,20.51,0,0,0,2.16-2.76,4.65,4.65,0,0,0,.76-2l.42.13-.12.57a5.26,5.26,0,0,1-1,2A22.27,22.27,0,0,1,78.24,9c-.59.66-1.21,1.29-1.84,1.92-.29.3-.64.54-1,.82s-.48.43-.74.63-.78.6-1.18.89l-.41.29A10.77,10.77,0,0,1,70.17,15l-.12.11-.16-.24Z"
    />
    <path
      className="cls-16"
      d="M.15,21.8c0-.17,0-.35-.07-.52a.76.76,0,0,0,.49-.11l1.05-.62c.51-.3,1-.59,1.52-.91.7-.45,1.38-.91,2.06-1.39A4.87,4.87,0,0,0,6,17.58c.21-.22.37-.5.57-.73.66-.74,1.18-1.58,1.77-2.36.4-.54.64-1.16,1-1.72a1.63,1.63,0,0,0,.09-.45l.52.09a5.11,5.11,0,0,1-.55,1.35,14.38,14.38,0,0,1-1.84,2.62,11.53,11.53,0,0,1-.84,1,10.59,10.59,0,0,1-1,1c-.43.37-.89.68-1.34,1A17.48,17.48,0,0,1,1.85,21c-.13.06-.25.14-.37.21A3.44,3.44,0,0,1,.15,21.8Z"
    />
    <path
      className="cls-19"
      d="M19,39.54a.72.72,0,0,1-.07.28c-.57.63-1,1.35-1.66,1.94s-.91,1-1.39,1.45-.73.63-1.1.94-1,.84-1.5,1.24a21.4,21.4,0,0,1-1.85,1.25,6.84,6.84,0,0,1-1.87.84,3.83,3.83,0,0,1-.58.07c-.1,0-.21,0-.25.11l-.17-.26.12-.18c.23-.07.47-.09.69-.16a8.83,8.83,0,0,0,2.43-1.23,21.43,21.43,0,0,0,2.16-1.63c.57-.53,1.2-1,1.77-1.53s1.1-1.12,1.62-1.7c.34-.38.63-.8.94-1.2.08-.09.22-.16.19-.31Z"
    />
    <path
      className="cls-17"
      d="M52.31,41.18A3.13,3.13,0,0,1,51.78,43L50.5,44.89a6.73,6.73,0,0,1-.6.72c-.9.93-1.78,1.89-2.72,2.79A18.32,18.32,0,0,1,45.26,50a16.42,16.42,0,0,1-2.9,1.86,5.29,5.29,0,0,1-1.56.46l-.13.08c-.06-.11-.13-.22-.2-.33a11.1,11.1,0,0,1,1.12-.34,5.88,5.88,0,0,0,1.61-.79c.63-.38,1.22-.81,1.82-1.23.24-.17.45-.38.68-.57s.6-.48.88-.72.65-.57,1-.86.66-.64,1-1,.6-.71.91-1.07l.67-.77a11.77,11.77,0,0,0,1.26-1.79,3.57,3.57,0,0,0,.55-1.3c0-.24,0-.48.07-.72C52.08,41,52.19,41.1,52.31,41.18Z"
    />
    <path
      className="cls-24"
      d="M48.52,37.57a4.05,4.05,0,0,1-.16,1.25,3.2,3.2,0,0,0-.09.62.48.48,0,0,0,.08.31.45.45,0,0,1,0,.52c-.26.45-.49.91-.77,1.35-.4.61-.84,1.19-1.26,1.79-.2.27-.38.55-.58.83a8.66,8.66,0,0,1-2.55,2.19c-.89.53-1.77,1.08-2.64,1.63a1.69,1.69,0,0,1-1.1.36.72.72,0,0,1-.07-.52,1.23,1.23,0,0,0,.81-.19c.25-.16.51-.3.74-.48a10.22,10.22,0,0,1,1.64-1l.8-.37a5.25,5.25,0,0,0,1.87-1.46,4.92,4.92,0,0,0,.48-.74c.2-.35.36-.73.57-1.07.35-.54.73-1.06,1.08-1.6a2.51,2.51,0,0,0,.54-1.26,9.91,9.91,0,0,1,.17-1.07c.06-.36.11-.72.16-1.08Z"
    />
    <path
      className="cls-21"
      d="M24.65,2.24h.69a3.52,3.52,0,0,1-.49,1.06c-.37.53-.72,1.07-1.07,1.61s-.57.94-.86,1.39a5.93,5.93,0,0,1-2.2,2.11,34,34,0,0,0-3.28,2,1.68,1.68,0,0,1-1,.37v-.69a1,1,0,0,0,.42-.05,9.77,9.77,0,0,0,1.29-.77,6.08,6.08,0,0,1,1.44-.87c.39-.16.77-.32,1.15-.5a3.75,3.75,0,0,0,1.7-1.71c.19-.35.37-.69.53-1A6.05,6.05,0,0,1,23.7,4a12.8,12.8,0,0,0,.77-1.15A.81.81,0,0,0,24.65,2.24Z"
    />
    <path
      className="cls-23"
      d="M13.62,34.89a5.21,5.21,0,0,0,.57-.09l.2-.19c.1-.21.17-.44.27-.66s.08-.34.3-.36l.13.43a1.26,1.26,0,0,0-.43.59c-.16.42-.29.85-.46,1.26,0,.12-.07.29-.28.3L13.81,36a2.44,2.44,0,0,1-.17.25,3.39,3.39,0,0,0-.91,1.72,2.22,2.22,0,0,1-.59,1.31,2.13,2.13,0,0,1-1.24.66,1.71,1.71,0,0,0-.45.09,6.8,6.8,0,0,0-1,.42c-.51.3-1,.64-1.48,1a.8.8,0,0,1-.56.15l.09-.68a1.61,1.61,0,0,0,.39-.12c.5-.3,1-.61,1.46-.92a2.49,2.49,0,0,1,1-.42,3.23,3.23,0,0,0,.82-.17A1.36,1.36,0,0,0,12.06,38a2.07,2.07,0,0,1,.44-1.2c.28-.41.59-.82.88-1.23A1,1,0,0,0,13.62,34.89Z"
    />
    <path
      className="cls-17"
      d="M18.44,39.46c0,.15-.11.22-.19.31-.31.4-.6.82-.94,1.2-.52.58-1.06,1.16-1.62,1.7s-1.2,1-1.77,1.53a21.43,21.43,0,0,1-2.16,1.63,8.83,8.83,0,0,1-2.43,1.23c-.22.07-.46.09-.69.16l-.12.18L8.26,47c.08-.26.33-.24.52-.28a6.59,6.59,0,0,0,2.43-1.12,16.39,16.39,0,0,0,2.09-1.52c.53-.51,1.12-1,1.67-1.44.36-.33.7-.68,1-1s.87-.91,1.28-1.38c.23-.26.43-.56.64-.84A.83.83,0,0,1,18.44,39.46Z"
    />
    <path
      className="cls-21"
      d="M67.55,8.59a3.74,3.74,0,0,1,.3-.77,5,5,0,0,1,1-1.24L69,6.52a.28.28,0,0,0,.24.21c.48.1,1,.21,1.45.35.17,0,.41,0,.48.26s-.24.44-.35.67-.2.33-.29.5-.27.53-.4.8l-.21-.09c-.15,0-.31,0-.46,0a13.32,13.32,0,0,1-1.39-.32C67.91,8.81,67.66,8.82,67.55,8.59Z"
    />
    <path
      className="cls-14"
      d="M13.62,34.89a1,1,0,0,1-.24.71c-.29.41-.6.82-.88,1.23a2.07,2.07,0,0,0-.44,1.2,1.36,1.36,0,0,1-.85,1.18,3.23,3.23,0,0,1-.82.17,2.49,2.49,0,0,0-1,.42c-.48.31-1,.62-1.46.92a1.61,1.61,0,0,1-.39.12,3.84,3.84,0,0,1,.34-1,.84.84,0,0,0,.63-.18l2.2-1.49.17-.19c.51-.74,1.08-1.45,1.64-2.14a1,1,0,0,0,.27-.74Z"
    />
    <path
      className="cls-27"
      d="M.15,21.8a3.44,3.44,0,0,0,1.33-.58c.12-.07.24-.15.37-.21a17.48,17.48,0,0,0,2.48-1.6c.45-.34.91-.65,1.34-1a10.59,10.59,0,0,0,1-1,11.53,11.53,0,0,0,.84-1,14.38,14.38,0,0,0,1.84-2.62,5.11,5.11,0,0,0,.55-1.35l.43.09A3.35,3.35,0,0,1,10,13.6a12.12,12.12,0,0,1-1.74,2.63,25.51,25.51,0,0,1-2.07,2.21c-.55.51-1.13,1-1.72,1.45a21.47,21.47,0,0,1-1.88,1.28c-.65.38-1.35.68-2,1a.93.93,0,0,1-.29.05Z"
    />
    <path
      className="cls-20"
      d="M70.14,15.43a3.94,3.94,0,0,0,1.16-.33A13,13,0,0,0,74,13.51a23,23,0,0,0,1.83-1.43c.83-.72,1.62-1.49,2.38-2.29a18,18,0,0,0,1.67-2A6.35,6.35,0,0,0,81,5.66c0-.22.1-.43.17-.65l.1-.11.24.17a7,7,0,0,1-.16.82,6.42,6.42,0,0,1-1,1.81,14.51,14.51,0,0,1-1.34,1.7c-.43.45-.8.95-1.24,1.39s-1,1-1.56,1.44-1.34,1.13-2,1.64a11.17,11.17,0,0,1-2.81,1.6,10.88,10.88,0,0,1-1.12.3C70.24,15.66,70.19,15.54,70.14,15.43Z"
    />
    <path
      className="cls-27"
      d="M16.55,11.81a1.75,1.75,0,0,0,.41-.09c.77-.38,1.52-.78,2.25-1.23a22.48,22.48,0,0,0,2.47-1.75,8,8,0,0,0,1.65-1.59c.31-.44.71-.81,1-1.23s.56-.81.86-1.2A9,9,0,0,0,26.18,3a2.44,2.44,0,0,0,.11-.62c.14,0,.3-.06.43.07,0,.18,0,.36-.09.54a6,6,0,0,1-1.12,2.09c-.36.49-.71,1-1.1,1.44s-.88,1-1.35,1.43-1.16,1.09-1.77,1.6c-.4.33-.85.6-1.26.92a12.07,12.07,0,0,1-2.15,1.23c-.29.13-.57.29-.87.42a1.07,1.07,0,0,1-.32.08C16.65,12.1,16.6,12,16.55,11.81Z"
    />
    <path
      className="cls-25"
      d="M39.74,50.31a9.35,9.35,0,0,0,2.38-1A18.27,18.27,0,0,0,44,48q1-.75,1.92-1.59a13.52,13.52,0,0,0,1-1.1,7.33,7.33,0,0,0,.57-.55c.45-.59,1-1.12,1.43-1.73a7.48,7.48,0,0,0,1.27-2.48,1.62,1.62,0,0,0,0-.38h.17l.26.17-.08.19A3.34,3.34,0,0,1,50,42.18c-.26.42-.53.83-.81,1.24a8,8,0,0,1-.69.85c-.57.62-1.14,1.24-1.74,1.83a20.45,20.45,0,0,1-1.59,1.5,23.43,23.43,0,0,1-2.8,2,9.77,9.77,0,0,1-1.71.78,1.82,1.82,0,0,1-.74.26Z"
    />
    <path
      className="cls-14"
      d="M75.06,4.65a1.65,1.65,0,0,1,.86-.25,1,1,0,0,1-.23.7c-.24.35-.5.68-.76,1a2.59,2.59,0,0,0-.56,1.54,1,1,0,0,1-.56.87,1.64,1.64,0,0,1-.92.26,1.43,1.43,0,0,0-.28.07,3.17,3.17,0,0,0-1.13.53c-.37.26-.76.48-1.14.72-.12.08-.26.13-.36,0s0-.23,0-.34.11-.23.16-.35a1.3,1.3,0,0,0,.41-.09,7.87,7.87,0,0,0,.91-.59,6.81,6.81,0,0,1,1.47-1l.16-.14c.19-.24.39-.48.56-.74A18,18,0,0,1,74.8,5.39,1,1,0,0,0,75.06,4.65Z"
    />
    <path
      className="cls-26"
      d="M47.58,37.62l.34-.06-.09.79a5,5,0,0,1-.11.88c0,.19,0,.43-.26.56l-.12.22a4.33,4.33,0,0,1-.84,1.34,5.43,5.43,0,0,0-.84,1.41c-.16.41-.37.81-.57,1.21a2.71,2.71,0,0,1-.84.94,6.91,6.91,0,0,1-1.78.84,2.14,2.14,0,0,0-.53.25L40.3,47.1a1.62,1.62,0,0,1-.83.28c0-.23,0-.47.09-.69a1.12,1.12,0,0,0,.79-.23c.43-.29.85-.59,1.3-.86a3.84,3.84,0,0,1,1.43-.55,1.59,1.59,0,0,0,.68-.21A2.54,2.54,0,0,0,45,43.11a3.3,3.3,0,0,1,.46-1.21c.2-.32.48-.59.7-.9a2,2,0,0,0,.56-1.2l.58-.1c.06-.45.13-.85.18-1.25S47.54,37.9,47.58,37.62Z"
    />
    <path
      className="cls-21"
      d="M39.47,47.38a1.62,1.62,0,0,0,.83-.28L41.94,46a2.14,2.14,0,0,1,.53-.25,6.91,6.91,0,0,0,1.78-.84,2.71,2.71,0,0,0,.84-.94c.2-.4.41-.8.57-1.21a5.43,5.43,0,0,1,.84-1.41A4.33,4.33,0,0,0,47.34,40l.12-.22c.23-.13.21-.37.26-.56a5,5,0,0,0,.11-.88l.09-.79.17,0,.17,0c0,.36-.1.72-.16,1.08a9.91,9.91,0,0,0-.17,1.07A2.51,2.51,0,0,1,47.39,41c-.35.54-.73,1.06-1.08,1.6-.21.34-.37.72-.57,1.07a4.92,4.92,0,0,1-.48.74,5.25,5.25,0,0,1-1.87,1.46l-.8.37a10.22,10.22,0,0,0-1.64,1c-.23.18-.49.32-.74.48a1.23,1.23,0,0,1-.81.19C39.42,47.73,39.45,47.56,39.47,47.38Z"
    />
    <path
      className="cls-26"
      d="M24.65,2.24a.81.81,0,0,1-.18.6A12.8,12.8,0,0,1,23.7,4,6.05,6.05,0,0,0,23,5.13c-.16.36-.34.7-.53,1a3.75,3.75,0,0,1-1.7,1.71c-.38.18-.76.34-1.15.5a6.08,6.08,0,0,0-1.44.87,9.77,9.77,0,0,1-1.29.77,1,1,0,0,1-.42.05l.09-.69a1.13,1.13,0,0,0,.82-.26l1.32-.9a4.11,4.11,0,0,1,1.47-.56,2.6,2.6,0,0,0,1.47-.93,4.52,4.52,0,0,0,.68-1.56,4.19,4.19,0,0,1,.63-1.13,11,11,0,0,0,.93-1.4,1.3,1.3,0,0,0,.09-.4Z"
    />
    <path
      className="cls-16"
      d="M7.33,44.28l-.09-.69a3.59,3.59,0,0,0,.68-.21c.75-.44,1.48-.9,2.22-1.36.47-.29.92-.6,1.39-.87a7.53,7.53,0,0,0,1.32-1,8.82,8.82,0,0,0,1.39-1.71,3.54,3.54,0,0,0,.5-.81h.17l.26.26c0,.12,0,.18.17.17-.53.62-1,1.26-1.58,1.87a8.22,8.22,0,0,1-1.52,1.36,10.39,10.39,0,0,0-1,.73,12.47,12.47,0,0,1-1.5,1c-.47.31-1,.51-1.46.84A2.05,2.05,0,0,1,7.33,44.28Z"
    />
    <path
      className="cls-20"
      d="M51.53,40.67a4.7,4.7,0,0,1-.8,2.27,18.24,18.24,0,0,1-1.14,1.52,15.07,15.07,0,0,1-1.29,1.47c-.37.36-.67.78-1.06,1.12a27.21,27.21,0,0,1-2.16,1.84c-.52.36-1,.8-1.51,1.15a8.86,8.86,0,0,1-2.43,1.24l-.92.25c-.1-.07-.17-.14-.14-.27a14.76,14.76,0,0,0,2.5-1.06,22.37,22.37,0,0,0,2.26-1.59,22,22,0,0,0,2.23-2c.51-.49,1-1,1.49-1.51.63-.72,1.19-1.5,1.75-2.27a4.52,4.52,0,0,0,.74-1.49l.05-.2a1,1,0,0,1,.18-.59Z"
    />
    <path
      className="cls-24"
      d="M16.47,10.77a1.68,1.68,0,0,0,1-.37,34,34,0,0,1,3.28-2,5.93,5.93,0,0,0,2.2-2.11c.29-.45.57-.93.86-1.39s.7-1.08,1.07-1.61a3.52,3.52,0,0,0,.49-1.06l.51.07c-.08.21-.17.42-.26.62a13.38,13.38,0,0,1-1.71,2.7c-.36.46-.68.94-1,1.39A5.89,5.89,0,0,1,22,8c-.48.39-1,.74-1.5,1.08-.69.45-1.38.86-2.08,1.29-.4.25-.81.5-1.23.73a5.67,5.67,0,0,1-.62.23C16.39,11.13,16.48,11,16.47,10.77Z"
    />
    <path
      className="cls-14"
      d="M5.43,12.75l.86-.26a.92.92,0,0,1-.22.71c-.25.3-.47.63-.7,1a2.37,2.37,0,0,0-.62,1.48,1.59,1.59,0,0,1-.37.87l-.16.12a4.59,4.59,0,0,1-.82.17,2.23,2.23,0,0,0-.87.28c-.5.31-1,.64-1.48,1a1.47,1.47,0,0,1-.71.24L.6,17.4c.37.08.62-.16.88-.33C2,16.74,2.47,16.33,3,16a4.3,4.3,0,0,0,1-.95c.37-.44.71-.91,1-1.38C5.23,13.41,5.52,13.16,5.43,12.75Z"
    />
    <path
      className="cls-21"
      d="M7.24,43c0-.26,0-.52,0-.77.46.09.78-.21,1.12-.41.49-.3.94-.67,1.43-1a3,3,0,0,1,.69-.29A4.74,4.74,0,0,0,11.76,40,2.85,2.85,0,0,0,13,38.69c.21-.5.43-1,.66-1.49a1.21,1.21,0,0,1,.29-.45.91.91,0,0,0,.34-.47c.14-.35.25-.71.41-1.05s.21-.76.51-1l.18.26A1.17,1.17,0,0,0,15,35a5.27,5.27,0,0,1-.19.51c-.15.38-.3.75-.44,1.13a.52.52,0,0,0,0,.54v.08l-.13.1c-.24.4-.45.81-.7,1.2a8.16,8.16,0,0,1-.79,1.13,4.93,4.93,0,0,1-1.43,1A13.76,13.76,0,0,0,9,42.05a10,10,0,0,1-1.31.83A.74.74,0,0,1,7.24,43Z"
    />
    <path
      className="cls-22"
      d="M7.56,45.24c0-.18-.1-.35-.14-.53l.24,0A13.86,13.86,0,0,0,9,44.06c.66-.36,1.32-.75,2-1.17a14.81,14.81,0,0,0,1.26-1c.57-.47,1.15-.93,1.68-1.43s.81-.91,1.21-1.37c.16-.18.31-.38.47-.55l.26-.13.33.1.09.16-.22.18c-.43.49-.85,1-1.3,1.46-.64.69-1.36,1.3-2.06,1.92s-1.18.91-1.78,1.35a11.44,11.44,0,0,1-2.26,1.28C8.24,45,7.89,45.11,7.56,45.24Z"
    />
    <path
      className="cls-24"
      d="M9.39,12.32a1.63,1.63,0,0,1-.09.45c-.35.56-.59,1.18-1,1.72-.59.78-1.11,1.62-1.77,2.36-.2.23-.36.51-.57.73a4.87,4.87,0,0,1-.77.67c-.68.48-1.36.94-2.06,1.39-.5.32-1,.61-1.52.91l-1.05.62a.76.76,0,0,1-.49.11c0-.17,0-.34-.07-.51a2,2,0,0,0,1.09-.39c.61-.37,1.19-.79,1.8-1.17a9.66,9.66,0,0,1,1-.56,6.09,6.09,0,0,0,1.9-1.4,12,12,0,0,0,.81-1.07c.42-.63.83-1.27,1.23-1.91s.63-1,.93-1.55a1.61,1.61,0,0,0,.12-.39Z"
    />
    <path
      className="cls-18"
      d="M16.94,13.19a3.81,3.81,0,0,0,1.2-.37,8.32,8.32,0,0,0,1.74-.93c.47-.32.94-.65,1.4-1a10.76,10.76,0,0,0,1.28-1,8.86,8.86,0,0,0,1.13-1.08,9,9,0,0,1,1-1c.27-.28.54-.57.79-.86s.62-.7.89-1.08a7.33,7.33,0,0,0,1.28-2.3c.06-.24.1-.49.15-.74l.34.2a1.17,1.17,0,0,0-.09.32,1.87,1.87,0,0,1-.21.8,10.92,10.92,0,0,1-1.18,1.94,10.79,10.79,0,0,1-.86,1c-.52.59-1,1.18-1.57,1.74s-1,.87-1.46,1.3l-1.26,1.08a7.52,7.52,0,0,1-.64.47c-.59.38-1.16.8-1.77,1.12a13.55,13.55,0,0,1-1.6.62c-.13,0-.27.06-.41.09S17,13.31,16.94,13.19Z"
    />
    <path
      className="cls-18"
      d="M.48,23.19a2.34,2.34,0,0,0,1.13-.31A11.72,11.72,0,0,0,4.7,21c.55-.46,1.13-.9,1.69-1.35l.21-.21c.63-.62,1.27-1.23,1.87-1.88a14.9,14.9,0,0,0,1.44-1.72A11.65,11.65,0,0,0,11,14.15a3.49,3.49,0,0,0,.31-1.3.48.48,0,0,1,.45.15l-.1.2A4.23,4.23,0,0,1,11,14.88c-.27.4-.54.82-.84,1.2-.48.61-1,1.22-1.48,1.8S7.62,19,7.08,19.5a5.7,5.7,0,0,1-.43.42c-.7.57-1.4,1.13-2.11,1.68a11.58,11.58,0,0,1-2.4,1.46c-.25.1-.5.18-.74.29a1.79,1.79,0,0,1-.82.18A3.37,3.37,0,0,0,.48,23.19Z"
    />
    <path
      className="cls-23"
      d="M70,10.08c.1.16.24.11.36,0,.38-.24.77-.46,1.14-.72a3.17,3.17,0,0,1,1.13-.53,1.43,1.43,0,0,1,.28-.07,1.64,1.64,0,0,0,.92-.26,1,1,0,0,0,.56-.87,2.59,2.59,0,0,1,.56-1.54c.26-.34.52-.67.76-1a1,1,0,0,0,.23-.7,2.11,2.11,0,0,1,.78-.18.76.76,0,0,1-.18.63L75.66,6A3.4,3.4,0,0,0,75,7.61a1.72,1.72,0,0,1-.36.88A2.42,2.42,0,0,1,73,9.4a3,3,0,0,0-1.63.69,4.28,4.28,0,0,1-.61.44c-.33.18-.62.46-1,.41,0-.11,0-.23,0-.34.17-.08,0-.23.09-.34Z"
    />
    <path
      className="cls-26"
      d="M15.17,34.19c-.3.28-.34.69-.51,1s-.27.7-.41,1.05a.91.91,0,0,1-.34.47,1.21,1.21,0,0,0-.29.45c-.23.49-.45,1-.66,1.49A2.85,2.85,0,0,1,11.76,40a4.74,4.74,0,0,1-1.27.53,3,3,0,0,0-.69.29c-.49.29-.94.66-1.43,1-.34.2-.66.5-1.12.41a1,1,0,0,1,.16-.7A.8.8,0,0,0,8,41.37c.49-.33,1-.67,1.48-1a6.8,6.8,0,0,1,1-.42,1.71,1.71,0,0,1,.45-.09,2.13,2.13,0,0,0,1.24-.66,2.22,2.22,0,0,0,.59-1.31,3.39,3.39,0,0,1,.91-1.72,2.44,2.44,0,0,0,.17-.25l.11.22c.21,0,.23-.18.28-.3.17-.41.3-.84.46-1.26a1.26,1.26,0,0,1,.43-.59Z"
    />
    <path
      className="cls-20"
      d="M.58,23.53a1.79,1.79,0,0,0,.82-.18c.24-.11.49-.19.74-.29a11.58,11.58,0,0,0,2.4-1.46c.71-.55,1.41-1.11,2.11-1.68a5.7,5.7,0,0,0,.43-.42c.54-.54,1.1-1.06,1.61-1.62s1-1.19,1.48-1.8c.3-.38.57-.8.84-1.2a4.23,4.23,0,0,0,.63-1.68l.1-.2.16.08a3.7,3.7,0,0,1-.62,2,12.09,12.09,0,0,1-.94,1.39c-.15.2-.31.41-.47.6s-.44.51-.67.76L7.88,19.22a13.15,13.15,0,0,1-1.43,1.26c-.53.41-1,.86-1.54,1.27a13.7,13.7,0,0,1-2.84,1.72c-.3.13-.64.2-.95.31A2.91,2.91,0,0,0,.77,24Z"
    />
    <path
      className="cls-27"
      d="M79.89,4.4a5.78,5.78,0,0,1-.12.57,6.19,6.19,0,0,1-1,1.88,7.1,7.1,0,0,1-.71,1c-.27.26-.41.61-.74.82A1.48,1.48,0,0,0,77,9c-.31.41-.72.7-1.06,1.07a22.28,22.28,0,0,1-2.58,2.17,15.56,15.56,0,0,1-1.85,1.17c-.55.31-1.16.53-1.75.79-.13-.12,0-.29-.08-.43.42,0,.71-.26,1.05-.41.13-.05.24-.14.37-.2a18.89,18.89,0,0,0,2.69-1.72,15.41,15.41,0,0,0,2.44-2.11c.47-.51.88-1.07,1.35-1.58a13.67,13.67,0,0,0,1-1.33,9.2,9.2,0,0,0,1-1.91V4.31Z"
    />
    <path
      className="cls-14"
      d="M22.06,15.67a3.09,3.09,0,0,0-2.23,1.07l-.26.33v-.18c0-.15.05-.3.07-.46a4,4,0,0,1,.16-1.18c0-.17.09-.35.13-.52a2.93,2.93,0,0,1,1.54-1.28,6.86,6.86,0,0,1,.78-.2,4.38,4.38,0,0,1,0,.51c-.06.38-.17.76-.22,1.14a1,1,0,0,0,.08.7Z"
    />
    <path
      className="cls-15"
      d="M17.93,39.37c-.21.28-.41.58-.64.84-.41.47-.85.92-1.28,1.38s-.68.7-1,1c-.55.49-1.14.93-1.67,1.44a16.39,16.39,0,0,1-2.09,1.52A6.59,6.59,0,0,1,8.78,46.7c-.19,0-.44,0-.52.28l-.17-.35a.76.76,0,0,1,.25-.19,10.7,10.7,0,0,0,1.89-.77,16.11,16.11,0,0,0,2.08-1.35,31.29,31.29,0,0,0,4.12-3.77c.37-.38.7-.81,1.05-1.22V39.2A.47.47,0,0,1,17.93,39.37Z"
    />
    <path
      className="cls-26"
      d="M69.73,10.94c.42.05.71-.23,1-.41a4.28,4.28,0,0,0,.61-.44A3,3,0,0,1,73,9.4a2.42,2.42,0,0,0,1.61-.91A1.72,1.72,0,0,0,75,7.61,3.4,3.4,0,0,1,75.66,6l.86-1.15a.76.76,0,0,0,.18-.63l.61-.07a1,1,0,0,1-.18.68c-.27.37-.5.78-.81,1.13a3.79,3.79,0,0,0-.81,1.55,3.86,3.86,0,0,1-.69,1.28,2.57,2.57,0,0,1-1.26.88l-.88.26a4.64,4.64,0,0,0-1.24.71l-1.19.77a.88.88,0,0,1-.61.13h0l0-.43Z"
    />
    <path
      className="cls-23"
      d="M.34,18.27A1.47,1.47,0,0,0,1.05,18c.5-.32,1-.65,1.48-1a2.23,2.23,0,0,1,.87-.28,4.59,4.59,0,0,0,.82-.17l.16-.12a1.59,1.59,0,0,0,.37-.87,2.37,2.37,0,0,1,.62-1.48c.23-.32.45-.65.7-1a.92.92,0,0,0,.22-.71L7,12.33a2.13,2.13,0,0,1-.56,1.19c-.26.39-.58.73-.8,1.14a4.7,4.7,0,0,0-.35,1.2A1.65,1.65,0,0,1,4,17.23l-.61.13a4.25,4.25,0,0,0-1.68.82c-.28.19-.58.34-.86.53a.92.92,0,0,1-.64.16C.23,18.67.29,18.47.34,18.27Z"
    />
    <path
      className="cls-28"
      d="M12.75,35.15a1,1,0,0,1-.27.74c-.56.69-1.13,1.4-1.64,2.14l-.17.19-2.2,1.49a.84.84,0,0,1-.63.18c.18-.4.36-.79.55-1.18a.41.41,0,0,1,.26-.19,1.09,1.09,0,0,0,.53-.25,8.74,8.74,0,0,0,2.07-2.09,3.81,3.81,0,0,0,.22-.45Z"
    />
    <path
      className="cls-28"
      d="M75.06,4.65a1,1,0,0,1-.26.74,18,18,0,0,0-1.14,1.47c-.17.26-.37.5-.56.74l-.16.14a6.81,6.81,0,0,0-1.47,1,7.87,7.87,0,0,1-.91.59,1.3,1.3,0,0,1-.41.09V9.31c.13-.27.26-.54.4-.8s.2-.33.29-.5a1.41,1.41,0,0,0,.86-.4,6.35,6.35,0,0,0,1-.92,5.45,5.45,0,0,0,1.07-1.37l.1-.13A3.42,3.42,0,0,1,75.06,4.65Z"
    />
    <path
      className="cls-18"
      d="M16.9,39.11l-.54.58-.62.7-1,1a19.19,19.19,0,0,1-2.14,1.88c-.57.41-1.12.86-1.69,1.25a9.76,9.76,0,0,1-2.32,1.18L7.81,46l-.15-.44.25,0c.33-.13.66-.25,1-.4.5-.23,1-.47,1.46-.74a18.74,18.74,0,0,0,1.55-1.09,20.87,20.87,0,0,0,2.2-1.87l.24-.18a3.45,3.45,0,0,0,.28-.26c.14-.16.26-.34.41-.49a19.92,19.92,0,0,0,1.53-1.66C16.73,38.85,16.81,39,16.9,39.11Z"
    />
    <path
      className="cls-24"
      d="M7.24,43a.74.74,0,0,0,.48-.11A10,10,0,0,0,9,42.05a13.76,13.76,0,0,1,2.31-1.29,4.93,4.93,0,0,0,1.43-1,8.16,8.16,0,0,0,.79-1.13c.25-.39.46-.8.7-1.2l.13-.1.26.18.09.17a3.54,3.54,0,0,1-.5.81,8.82,8.82,0,0,1-1.39,1.71,7.53,7.53,0,0,1-1.32,1c-.47.27-.92.58-1.39.87-.74.46-1.47.92-2.22,1.36a3.59,3.59,0,0,1-.68.21Z"
    />
    <path
      className="cls-28"
      d="M5.43,12.75c.09.41-.2.66-.39.94-.33.47-.67.94-1,1.38A4.3,4.3,0,0,1,3,16c-.53.31-1,.72-1.52,1.05-.26.17-.51.41-.88.33.16-.31.31-.62.47-.94a.71.71,0,0,1,.42-.37,2.52,2.52,0,0,0,.77-.49A15.39,15.39,0,0,0,3.88,14a2.36,2.36,0,0,0,.28-.52.48.48,0,0,1,.35-.31,5.91,5.91,0,0,0,.76-.37Z"
    />
    <path
      className="cls-28"
      d="M44.73,40.32c.12,0,.1.14.07.21a4.77,4.77,0,0,1-.36.62c-.33.43-.67.87-1,1.29a7,7,0,0,1-1.29,1.2c-.45.32-.92.61-1.35.94a1,1,0,0,1-.71.22,7.92,7.92,0,0,1,.65-1.22l.24-.21a2.31,2.31,0,0,0,1-.64,4.84,4.84,0,0,0,1.33-1.58.72.72,0,0,1,.49-.42A8.34,8.34,0,0,0,44.73,40.32Z"
    />
    <path
      className="cls-23"
      d="M46.71,39.8a2,2,0,0,1-.56,1.2c-.22.31-.5.58-.7.9A3.3,3.3,0,0,0,45,43.11a2.54,2.54,0,0,1-1.23,1.73,1.59,1.59,0,0,1-.68.21,3.84,3.84,0,0,0-1.43.55c-.45.27-.87.57-1.3.86a1.12,1.12,0,0,1-.79.23l.08-.6a1.52,1.52,0,0,0,1-.36l1-.68a4,4,0,0,1,1.3-.42c.3-.09.59-.17.88-.27l.17-.11a6.19,6.19,0,0,0,.45-.89c.1-.3.1-.63.19-.94a2.23,2.23,0,0,1,.48-.83c.23-.28.47-.56.68-.86a1.35,1.35,0,0,0,.2-.5c.05-.23.11-.37.38-.35A1.36,1.36,0,0,0,46.71,39.8Z"
    />
    <path
      className="cls-27"
      d="M15.78,38.42l-.26.13c-.16.17-.31.37-.47.55-.4.46-.77.94-1.21,1.37s-1.11,1-1.68,1.43a14.81,14.81,0,0,1-1.26,1c-.63.42-1.29.81-2,1.17a13.86,13.86,0,0,1-1.29.61l-.24,0-.09-.43a2.05,2.05,0,0,0,1-.37c.46-.33,1-.53,1.46-.84a12.47,12.47,0,0,0,1.5-1,10.39,10.39,0,0,1,1-.73A8.22,8.22,0,0,0,13.76,40c.55-.61,1.05-1.25,1.58-1.87h.08c.14.06.16.27.35.25Z"
    />
    <path
      className="cls-15"
      d="M17.32,14.13a4.78,4.78,0,0,1,.48-.19A9.7,9.7,0,0,0,21,12.33a20.15,20.15,0,0,0,2.13-1.68c.48-.47,1-.89,1.52-1.37a17.84,17.84,0,0,0,1.46-1.55c.63-.78,1.24-1.57,1.79-2.41a4.19,4.19,0,0,0,.74-1.9l.11-.16.16.08c0,.21-.06.42-.1.62a1.68,1.68,0,0,1-.11.4,10.76,10.76,0,0,1-1.28,2.17A26.79,26.79,0,0,1,24,10.22a22.54,22.54,0,0,1-2.25,1.87c-.5.36-1,.71-1.52,1.06a7.53,7.53,0,0,1-2.45,1.06l-.41.1Z"
    />
    <path
      className="cls-25"
      d="M16.55,38.85A19.92,19.92,0,0,1,15,40.51c-.15.15-.27.33-.41.49a3.45,3.45,0,0,1-.28.26l-.24.18a20.87,20.87,0,0,1-2.2,1.87,18.74,18.74,0,0,1-1.55,1.09c-.47.27-1,.51-1.46.74-.31.15-.64.27-1,.4l-.25,0c0-.11-.07-.22-.1-.33.33-.13.68-.23,1-.37a11.44,11.44,0,0,0,2.26-1.28c.6-.44,1.21-.86,1.78-1.35S14,41,14.68,40.32c.45-.47.87-1,1.3-1.46l.22-.18h.26Z"
    />
    <path
      className="cls-10"
      d="M51.53,40.67l-.25-.09a.47.47,0,0,0-.43-.18,1.9,1.9,0,0,0,.17-.85l0-.11.12-.83c0-.12.06-.24.09-.36a2.55,2.55,0,0,1,.89.6,2.64,2.64,0,0,1,.93,1.5l.06,0c-.06.35-.11.69-.17,1h-.25c-.17,0-.21-.29-.43-.26s-.23-.17-.34-.26Z"
    />
    <path
      className="cls-10"
      d="M69.71,13.79c0,.14,0,.31.08.43s-.06.31.09.43v.21l-2.24-.54a2.27,2.27,0,0,1-.7-1.5l-.07-.16a7.47,7.47,0,0,1,1.55.31,2.45,2.45,0,0,0,1.17.13l0,.08C69.66,13.38,69.69,13.59,69.71,13.79Z"
    />
    <path
      className="cls-22"
      d="M66.19,30l.51.17v.25a7,7,0,0,1-.89,2c-.3.39-.62.77-.9,1.18A15.53,15.53,0,0,1,63.75,35c-.39.45-.82.87-1.24,1.3l-.12,0v-.78l0-.08c.24,0,.35-.15.48-.29s.17-.23.26-.33c.4-.47.82-.93,1.22-1.41A9.89,9.89,0,0,0,65.9,31,2.42,2.42,0,0,0,66.19,30Z"
    />
    <path
      className="cls-28"
      d="M21.72,2.84a.9.9,0,0,1-.24.62c-.29.38-.62.74-.92,1.11s-.38.52-.61.75a4.92,4.92,0,0,1-.72.65c-.47.35-1,.68-1.44,1a.8.8,0,0,1-.56.16l.05-.24a5.24,5.24,0,0,1,.3-.51.93.93,0,0,1,.53-.48,2.63,2.63,0,0,0,.82-.6c.39-.36.76-.73,1.12-1.12.17-.19.29-.42.45-.62a1.56,1.56,0,0,1,.41-.4A1.68,1.68,0,0,1,21.72,2.84Z"
    />
    <path
      className="cls-18"
      d="M67.58,30.49c-.06.1-.17.2-.18.31a3.27,3.27,0,0,1-.61,1.59c-.23.35-.44.72-.68,1.07a7.25,7.25,0,0,1-.54.7c-.32.38-.67.74-1,1.11s-.52.6-.8.88c-.48.47-1,.93-1.48,1.39a1.72,1.72,0,0,1-.41.19l.35-.77c.17,0,.25-.12.34-.22.69-.7,1.37-1.41,2-2.13a10.24,10.24,0,0,0,.76-.85,19.51,19.51,0,0,0,1.11-1.63,3.41,3.41,0,0,0,.57-1.69l.1-.12Z"
    />
    <path
      className="cls-25"
      d="M67.15,30.32l-.1.12a3.41,3.41,0,0,1-.57,1.69,19.51,19.51,0,0,1-1.11,1.63,10.24,10.24,0,0,1-.76.85c-.68.72-1.36,1.43-2,2.13-.09.1-.17.24-.34.22a1.38,1.38,0,0,1,.17-.69l.12,0c.42-.43.85-.85,1.24-1.3a15.53,15.53,0,0,0,1.16-1.39c.28-.41.6-.79.9-1.18a7,7,0,0,0,.89-2v-.25Z"
    />
    <path
      className="cls-27"
      d="M66.19,30a2.42,2.42,0,0,1-.29,1,9.89,9.89,0,0,1-1.53,2.4c-.4.48-.82.94-1.22,1.41-.09.1-.17.22-.26.33s-.24.33-.48.29a.92.92,0,0,0,0-.52l.17-.1,1.19-1.48c.24-.31.48-.62.71-.94a14.71,14.71,0,0,0,1.13-2.11,1.35,1.35,0,0,0,.07-.36Z"
    />
    <path
      className="cls-5"
      d="M15.17,34.19,15.09,34,15,33.59a2.38,2.38,0,0,1,.22-1c.18-.42.35-.84.52-1.25l.27.1c.26.27.5.56.75.85l-.09.08-.69,1.72c-.12.32-.14.34-.52.35h-.07Z"
    />
    <path
      className="cls-16"
      d="M65.67,29.9a1.35,1.35,0,0,1-.07.36,14.71,14.71,0,0,1-1.13,2.11c-.23.32-.47.63-.71.94l-1.19,1.48-.17.1c0-.26,0-.52,0-.77a1.77,1.77,0,0,0,.61-.69,2.29,2.29,0,0,1,.28-.41,17.22,17.22,0,0,0,1.64-2.57,1.21,1.21,0,0,0,.15-.64A1,1,0,0,1,65.67,29.9Z"
    />
    <path
      className="cls-24"
      d="M65.07,29.81a1.21,1.21,0,0,1-.15.64A17.22,17.22,0,0,1,63.28,33a2.29,2.29,0,0,0-.28.41,1.77,1.77,0,0,1-.61.69l-.18-.52c.12-.15.25-.29.36-.45.53-.79,1-1.58,1.57-2.38a2.26,2.26,0,0,0,.41-1Z"
    />
    <path
      className="cls-26"
      d="M63.08,29.81l.87-.08a1.9,1.9,0,0,1-.34.89c-.11.15-.24.28-.34.44a12.91,12.91,0,0,0-1.09,1.73,1.23,1.23,0,0,0-.13.48l-.08,0a.87.87,0,0,0-.44-.52v-.14a1.1,1.1,0,0,1,.26-.65c.29-.4.61-.78.9-1.18A1.59,1.59,0,0,0,63.08,29.81Z"
    />
    <path
      className="cls-9"
      d="M51.35,19.84a.86.86,0,0,1-.25-.66c.21-.57.42-1.15.64-1.71.51-1.29,1-2.57,1.54-3.86l.08-.09.35.43-.2.21c-.15.32-.29.66-.42,1l-.78,2c-.2.51-.42,1-.61,1.52A3,3,0,0,0,51.35,19.84Z"
    />
    <path
      className="cls-21"
      d="M62.05,33.27a1.23,1.23,0,0,1,.13-.48,12.91,12.91,0,0,1,1.09-1.73c.1-.16.23-.29.34-.44a1.9,1.9,0,0,0,.34-.89h.6a2.26,2.26,0,0,1-.41,1c-.52.8-1,1.59-1.57,2.38-.11.16-.24.3-.36.45Z"
    />
    <path
      className="cls-23"
      d="M63.08,29.81a1.59,1.59,0,0,1-.4,1c-.29.4-.61.78-.9,1.18a1.1,1.1,0,0,0-.26.65l-.61-.36.09-.07c0-.23.13-.37.23-.53s.43-.55.64-.82a1.35,1.35,0,0,0,.35-.83Z"
    />
    <path
      className="cls-14"
      d="M62.22,30a1.35,1.35,0,0,1-.35.83c-.21.27-.45.54-.64.82s-.27.3-.23.53l-.09.07-.6-.23a1.31,1.31,0,0,1,.12-.35,8,8,0,0,1,.64-.89.58.58,0,0,0,.12-.43A3.14,3.14,0,0,1,62.22,30Z"
    />
    <path
      className="cls-18"
      d="M58.69,40.15l-.09.16a7.22,7.22,0,0,1-1.53.62c-.19,0-.42.06-.54.26l-.27-.77,0-.07c.35,0,.61-.23.93-.3l.17-.17A4.71,4.71,0,0,0,58.69,40.15Z"
    />
    <path
      className="cls-24"
      d="M71.19,7.34c-.07-.25-.31-.21-.48-.26-.48-.14-1-.25-1.45-.35A.28.28,0,0,1,69,6.52l.24-.14.12-.09c.09,0,.15-.08.27,0,.7.18,1.41.34,2.11.5A1.07,1.07,0,0,1,71.19,7.34Z"
    />
    <path
      className="cls-10"
      d="M19.79,15.25a4,4,0,0,0-.16,1.18c0,.16-.05.31-.07.46l-.22-.09a5.69,5.69,0,0,1-1.14-1.21l.24-.08A3.31,3.31,0,0,0,19.79,15.25Z"
    />
    <path
      className="cls-28"
      d="M61.19,30.33a.58.58,0,0,1-.12.43,8,8,0,0,0-.64.89,1.31,1.31,0,0,0-.12.35l-.67-.28c.08-.45.2-.87.7-1C60.63,30.56,60.86,30.32,61.19,30.33Z"
    />
    <path
      className="cls-11"
      d="M19.79,15.25a3.31,3.31,0,0,1-1.35.26l-.24.08L18,15.28a.27.27,0,0,1,.33-.2,1.77,1.77,0,0,0,1-.22,1.59,1.59,0,0,1,.64-.13C19.88,14.9,19.83,15.08,19.79,15.25Z"
    />
    <path
      className="cls-22"
      d="M57.4,39.88l-.17.17c-.32.07-.58.32-.93.3l0,.07A2.23,2.23,0,0,1,56,39.29l.35.07Z"
    />
    <path
      className="cls-17"
      d="M59.64,31.72l.67.28.6.23.61.36v.14l-.15.06c-.19-.09-.37-.21-.56-.28a4.75,4.75,0,0,0-1.1-.37l-.44-.49Z"
    />
    <path
      className="cls-17"
      d="M17.16,19.52c0,.22.21.2.32.3l.19.34a2.8,2.8,0,0,1-.28-.06l-.76-.28-.08-.44Z"
    />
    <polygon
      className="cls-10"
      points="32.84 12.32 32.75 12.21 32.89 12.15 32.84 12.32"
    />
    <polygon
      className="cls-10"
      points="67.4 45.67 67.46 45.5 67.57 45.59 67.4 45.67"
    />
    <path className="cls-17" d="M16.55,19.38l-.08-.1Z" />
    <path className="cls-1" d="M58.72,22.33l.05-.1Z" />
    <path
      className="cls-4"
      d="M42.14,30.58c0,.36-.1.71-.14,1.06s0,.44,0,.67c-.9-.31-1.84-.45-2.75-.69s-1.65-.41-2.48-.59c-1-.22-2-.41-2.94-.62L32,30l-2.11-.51L27.66,29c-.2,0-.41-.05-.62-.1-.44-.1-.56-.28-.49-.73,0-.13.1-.25,0-.39v-.08l.25-.6c.59.12,1.18.22,1.76.37l2.56.59,2.24.52c1,.25,2,.44,3,.68.71.16,1.41.34,2.11.51L39.5,30Z"
    />
    <path
      className="cls-29"
      d="M42,32.91a2.08,2.08,0,0,0,1.17-.44,11.59,11.59,0,0,1,1.67-1c.24-.1.48-.21.73-.3a6.47,6.47,0,0,0,2.09-1.07,6,6,0,0,0,.71-.79,6.09,6.09,0,0,0,1-2v-.23a1.52,1.52,0,0,1,.07-1.3c.34-.84.7-1.68,1-2.52s.62-1.58.93-2.38c.06-.16.12-.34.18-.51l.25.18c.09.11.18.22.26.34l-.34.85-1,2.42L50,26.14c-.1.25-.19.5-.3.74a.33.33,0,0,0,.11.45l.46.37a.41.41,0,0,1,0,.63c-.42.5-.81,1-1.25,1.5a17.83,17.83,0,0,1-1.35,1.25c-.42.35-.87.67-1.32,1s-1.17.81-1.78,1.19-1,.53-1.49.8a1.65,1.65,0,0,1-1,.23,3.27,3.27,0,0,0-.09-.56A2.29,2.29,0,0,1,42,32.91Z"
    />
    <path
      className="cls-29"
      d="M42.14,30.58,39.5,30l-1.06-.26c-.7-.17-1.4-.35-2.11-.51-1-.24-2-.43-3-.68L31.12,28l-2.56-.59c-.58-.15-1.17-.25-1.76-.37a1.16,1.16,0,0,1,.17-.77c.55.11,1.09.22,1.64.35.72.16,1.43.33,2.15.51,1.08.27,2.17.48,3.25.75,1.23.31,2.47.56,3.7.87.85.21,1.72.35,2.58.53l1.83.43a.21.21,0,0,1,.19.24A1.11,1.11,0,0,1,42.14,30.58Z"
    />
    <path
      className="cls-4"
      d="M24.56,29.57a1.37,1.37,0,0,1-1.3-1.06,1.32,1.32,0,0,1,.21-1.15,1.6,1.6,0,0,1,.94-.67c.32-.09.61.08.91,0l.17.11a4.17,4.17,0,0,0,.37.36A1.17,1.17,0,0,1,26,28.72a1.5,1.5,0,0,1-1,.77Z"
    />
    <path
      className="cls-4"
      d="M15.42,34.46c.38,0,.4,0,.52-.35l.69-1.72.09-.08.41.28c.14.1.27.22.41.31s.39.37.27.66c-.23.58-.5,1.15-.74,1.73-.06.15-.26.25-.18.46h-.17l-.15-.09a.72.72,0,0,0-.37-.34L16,35.13a.28.28,0,0,0-.27-.24l-.18-.25Z"
    />
    <path
      className="cls-17"
      d="M25.59,13.53l.05.06c.3.13.35.2.29.45-.08.41-.17.82-.24,1.23s-.07.56-.1.84L25.17,16l-.1-.16a1.13,1.13,0,0,0,.18-.57c.05-.28.11-.57.15-.85s.07-.61.11-.91Z"
    />
    <path
      className="cls-19"
      d="M16.46,38.68H16.2l-.09-.16c.2-.64.46-1.25.71-1.86.1-.23.23-.44.34-.65.15,0,.28,0,.34.17a3.92,3.92,0,0,0-.34.64c-.15.39-.28.79-.47,1.17A4.3,4.3,0,0,0,16.46,38.68Z"
    />
    <path
      className="cls-17"
      d="M17.16,36c-.11.21-.24.42-.34.65-.25.61-.51,1.22-.71,1.86l-.33-.1v-.09c.26-.62.53-1.24.76-1.87.08-.23.26-.44.19-.71h.17Z"
    />
    <path
      className="cls-6"
      d="M53,21.62l-.52-.42c.14-.32.29-.62.43-.94.29-.68.58-1.36.85-2.05.34-.85.65-1.72,1-2.57.07-.17.1-.4.32-.48l.16,0,.73.47c-.13.32-.25.63-.37.94l-1.3,3.27c-.24.6-.48,1.21-.73,1.82l-.13.1Z"
    />
    <path
      className="cls-7"
      d="M51.79,20.59l-.25-.18,0-.15a.48.48,0,0,1-.15-.42,3,3,0,0,1,.35-1.19c.19-.51.41-1,.61-1.52l.78-2c.13-.33.27-.67.42-1l.2-.21c.21.19.48.32.59.61-.09.09-.21.16-.26.26-.27.62-.52,1.24-.77,1.86-.42,1.07-.85,2.15-1.26,3.23C51.92,20.13,51.86,20.36,51.79,20.59Z"
    />
    <path
      className="cls-6"
      d="M66.87,12.06a8,8,0,0,1-1.61-.29c-.31,0-.62-.15-.93-.22-.13,0-.21-.09-.21-.22,0-.4,0-.8,0-1.2l.06-.05,1.56.3A4,4,0,0,0,67,10.6v.51c-.14.16,0,.35-.06.52S66.87,11.92,66.87,12.06Z"
    />
    <path
      className="cls-12"
      d="M55.07,15.16c-.22.08-.25.31-.32.48-.34.85-.65,1.72-1,2.57-.27.69-.56,1.37-.85,2.05-.14.32-.29.62-.43.94l-.34-.27c.06-.18.1-.37.17-.55.34-.88.69-1.76,1-2.63s.63-1.55.94-2.32c.09-.23.18-.47.26-.7Z"
    />
    <path
      className="cls-13"
      d="M54.55,14.73c-.08.23-.17.47-.26.7-.31.77-.63,1.54-.94,2.32s-.7,1.75-1,2.63c-.07.18-.11.37-.17.55h-.09c-.08-.12-.17-.23-.26-.34.07-.23.13-.46.22-.68.41-1.08.84-2.16,1.26-3.23.25-.62.5-1.24.77-1.86.05-.1.17-.17.26-.26A.22.22,0,0,1,54.55,14.73Z"
    />
    <path
      className="cls-12"
      d="M67,10.6a4,4,0,0,1-1.19-.22l-1.56-.3a1.39,1.39,0,0,1,.17-.6,4.7,4.7,0,0,1,1.39.22c.43.08.86.2,1.29.3,0,.14,0,.28,0,.43Z"
    />
    <path
      className="cls-13"
      d="M67.05,10c-.43-.1-.86-.22-1.29-.3a4.7,4.7,0,0,0-1.39-.22c0-.12,0-.23,0-.34a.26.26,0,0,1,.17-.27,7.66,7.66,0,0,1,1.63.3c.35.06.69.15,1,.22v.26c0,.11-.06.23-.08.34Z"
    />
    <path
      className="cls-19"
      d="M66.87,12.06c0-.14,0-.28,0-.43l1,.23a8.27,8.27,0,0,0,1.71.29c0,.17,0,.35,0,.52h0a5,5,0,0,1-1.44-.21c-.41-.08-.81-.2-1.21-.3Z"
    />
    <path
      className="cls-11"
      d="M66.88,12.15c.4.1.8.22,1.21.3a5,5,0,0,0,1.44.21c0,.15,0,.29.07.44A2.45,2.45,0,0,1,68.43,13a7.47,7.47,0,0,0-1.55-.31Z"
    />
    <path
      className="cls-3"
      d="M62.39,36.27a1.38,1.38,0,0,0-.17.69l-.35.77-.08.09-.08.09a5.41,5.41,0,0,1-1.57,1.76l-.24,0-2.08-.78a5.37,5.37,0,0,1,.57-.5,2.74,2.74,0,0,0,.73-.75,6.09,6.09,0,0,0,1-2,3.68,3.68,0,0,0,.15-1l.08-.21.23.1a3.37,3.37,0,0,0,.31.21,5.17,5.17,0,0,1,1.45.72h.08Z"
    />
    <path
      className="cls-4"
      d="M52.55,36.09a.76.76,0,0,1-.75-.29l-.21-.14a1,1,0,0,1-.43-.62,1.85,1.85,0,0,1,.29-1.52.85.85,0,0,1,.25-.21c.11-.07.23-.11.34-.18a1.39,1.39,0,0,1,1.47,0,1.22,1.22,0,0,1,.72.93,2.06,2.06,0,0,1-.06.9,1.74,1.74,0,0,1-1.62,1.15Z"
    />
    <path
      className="cls-10"
      d="M62.39,35.49h-.08a5.17,5.17,0,0,0-1.45-.72,3.37,3.37,0,0,1-.31-.21l-.23-.1A6.6,6.6,0,0,0,60.11,33a1.75,1.75,0,0,1,0-.33l.12-.08c.35.14.69.29,1,.42a1.85,1.85,0,0,0,.75.25l.08,0,.16.33.18.52c0,.25,0,.51,0,.77a.92.92,0,0,1,0,.52Z"
    />
    <path
      className="cls-4"
      d="M49.72,35.15a10,10,0,0,1,1,.2.37.37,0,0,1,.22.28,4.73,4.73,0,0,1-.05.75c0,.17-.06.34-.07.5a6,6,0,0,1-.21,1h-.18l-.34-.1a.46.46,0,0,0-.43-.16h-.09l-.26-.09a2.47,2.47,0,0,0,.2-.81,2,2,0,0,1,.08-.53A3.5,3.5,0,0,0,49.72,35.15Z"
    />
    <path
      className="cls-29"
      d="M49.72,35.15a3.5,3.5,0,0,1-.15,1.07,2,2,0,0,0-.08.53,2.47,2.47,0,0,1-.2.81h-1l-.17,0c0-.33.08-.66.13-1s.14-.84.21-1.26l-.08-.12a.56.56,0,0,1,.58-.07A3.06,3.06,0,0,0,49.72,35.15Z"
    />
    <path
      className="cls-10"
      d="M57.82,38.94l2.08.78a2.53,2.53,0,0,1-1.21.43,4.71,4.71,0,0,1-1.29-.27l-1-.52.13-.07a2,2,0,0,0,1-.25A1.48,1.48,0,0,1,57.82,38.94Z"
    />
    <path
      className="cls-19"
      d="M51.28,38.25c0,.12-.07.24-.09.36l-.12.83,0,.11a1.9,1.9,0,0,1-.17.85l-.18-.08-.26-.17c.06-.23.13-.45.17-.68.1-.46.18-.93.27-1.39A.43.43,0,0,1,51.28,38.25Z"
    />
    <path
      className="cls-17"
      d="M50.85,38.08c-.09.46-.17.93-.27,1.39,0,.23-.11.45-.17.68h-.17l-.17-.09.09-.49c.05-.29.11-.58.16-.86s.06-.53.09-.8h.18Z"
    />
    <path
      className="cls-11"
      d="M62,33.25a1.85,1.85,0,0,1-.75-.25c-.35-.13-.69-.28-1-.42l-.12.08a1,1,0,0,1-.34-.52,4.75,4.75,0,0,1,1.1.37c.19.07.37.19.56.28l.15-.06A.87.87,0,0,1,62,33.25Z"
    />
    <path
      className="cls-1"
      d="M17.67,20.16l-.19-.34a1,1,0,0,1,.6.16c.35.15.71.25,1.05.4a6.1,6.1,0,0,1,.6.38,3.66,3.66,0,0,1,.69,1.38,5.69,5.69,0,0,1,.09,1.3v.68l-.07.05a2.78,2.78,0,0,1-.28.9,10.11,10.11,0,0,1-.48,1.06A4.14,4.14,0,0,1,17.41,28a2,2,0,0,1-1.36,0l-1.52-.58c-.11-.05-.21-.12-.31-.17a3.13,3.13,0,0,0,1.69-.42,4.6,4.6,0,0,0,1.31-1.21,5.18,5.18,0,0,0,1-2.15c.06-.37.09-.75.13-1.13a3.17,3.17,0,0,0-.25-1.52c-.05-.13-.12-.25-.17-.37l-.18-.26-.08-.09Z"
    />
    <path
      className="cls-29"
      d="M20.51,24.12v-.68a5.69,5.69,0,0,0-.09-1.3.23.23,0,0,0,.26-.17,2.74,2.74,0,0,0,.17-.48,5.32,5.32,0,0,1,.49-1.33c.31-.56.69-1.09,1-1.64a1.89,1.89,0,0,0,.21-.54c.1-.47.18-.94.25-1.4.05-.29.07-.58.1-.81l-.27-.2.1-.06h.43l.51,0,.27.11a2.1,2.1,0,0,1-.05,1.24c0,.21-.09.43-.12.65s.14.36.37.46c.45.18.48.24.34.72a6,6,0,0,1-1.06,2c-.27.35-.51.71-.77,1.06s-.36.46-.56.69c-.43.5-.87,1-1.32,1.49A1,1,0,0,1,20.51,24.12Z"
    />
    <path
      className="cls-22"
      d="M24,15.66l-.27-.11c0-.29,0-.58.07-.88a12.57,12.57,0,0,0,.2-1.5l.6.1c0,.35,0,.7-.09,1.05s-.17.9-.26,1.35Z"
    />
    <path
      className="cls-18"
      d="M24.21,15.67c.09-.45.19-.9.26-1.35s.06-.7.09-1.05a.66.66,0,0,1,.61.17c-.07.17-.2.3-.18.51a3.77,3.77,0,0,1-.16,1.11,5.52,5.52,0,0,0-.1.71Z"
    />
    <path
      className="cls-15"
      d="M24.73,15.77a5.52,5.52,0,0,1,.1-.71A3.77,3.77,0,0,0,25,14c0-.21.11-.34.18-.51l.34.09c0,.3-.07.61-.11.91s-.1.57-.15.85a1.13,1.13,0,0,1-.18.57Z"
    />
    <path
      className="cls-10"
      d="M45.24,28.68a1.31,1.31,0,0,1,1.08-1.34,1.26,1.26,0,0,1,.74.22,1.35,1.35,0,0,1,.33.56.49.49,0,0,1,0,.29,1.65,1.65,0,0,1-1.18,1,.63.63,0,0,1-.63-.15C45.36,29.12,45.15,29,45.24,28.68Z"
    />
    <path
      className="cls-19"
      d="M17.06,45.2a1,1,0,0,1,1.05-1.1A1,1,0,0,1,19,45,1.15,1.15,0,0,1,18,46.1a1.13,1.13,0,0,1-.69-.36A.54.54,0,0,1,17.06,45.2Z"
    />
    <path
      className="cls-19"
      d="M66.72,39a1,1,0,0,1,1.1,1,1.25,1.25,0,0,1-1.27,1.2A1.13,1.13,0,0,1,66.72,39Z"
    />
    <path
      className="cls-19"
      d="M9.74,22.67a1.16,1.16,0,0,1,1.52-1.1,1.07,1.07,0,0,1,.49,1.6,1.43,1.43,0,0,1-.77.56.75.75,0,0,1-.57,0,4.48,4.48,0,0,1-.47-.28c-.16-.12-.16-.32-.2-.49Z"
    />
    <path
      className="cls-19"
      d="M80.49,13.45c.39-.13.58.2.83.43a.74.74,0,0,1,.2.7,1.48,1.48,0,0,1-.83,1,1.08,1.08,0,0,1-1.42-1,1.38,1.38,0,0,1,.54-1A.87.87,0,0,1,80.49,13.45Z"
    />
    <path
      className="cls-19"
      d="M50.13,51.26a1,1,0,0,1-1-1.09A1.22,1.22,0,0,1,50.27,49a1.12,1.12,0,0,1,1.09,1.1A1.25,1.25,0,0,1,50.13,51.26Z"
    />
    <path
      className="cls-3"
      d="M17.75,20.25l.18.26c-.3,0-.52-.16-.79-.23-.09,0-.17-.1-.26-.11s-.26-.17-.25-.35l.76.28a2.8,2.8,0,0,0,.28.06h0Z"
    />
    <path
      className="cls-19"
      d="M27.16,13.7a1,1,0,0,1-.93-1.27,1.13,1.13,0,0,1,1.38-.88.73.73,0,0,1,.52.34,2,2,0,0,1,.26.53.7.7,0,0,1,0,.31A1.17,1.17,0,0,1,27.16,13.7Z"
    />
    <path
      className="cls-3"
      d="M25.51,13.53l-.34-.09a.66.66,0,0,0-.61-.17l-.6-.1c-.17-.15-.34-.08-.52,0l-.19-.05c-.09,0-.18-.06-.24.05l-.18-.07a11,11,0,0,0,1.63-1.16,1.81,1.81,0,0,1,1.24-.3,5.76,5.76,0,0,1-.13.89.74.74,0,0,0,0,.53,1.46,1.46,0,0,1,0,.47Z"
    />
    <path
      className="cls-23"
      d="M22.06,15.6a1,1,0,0,1-.08-.7c.05-.38.16-.76.22-1.14a4.38,4.38,0,0,0,0-.51l.32-.11.11,0c-.08.49-.16,1-.23,1.47l-.12.9Z"
    />
    <path
      className="cls-26"
      d="M22.32,15.56l.12-.9c.07-.49.15-1,.23-1.47l.16-.09.18.07c0,.32,0,.65-.1,1a8,8,0,0,0-.16,1.38l-.1.06Z"
    />
    <path
      className="cls-23"
      d="M47.58,37.62c0,.28-.07.56-.11.83s-.12.8-.18,1.25l-.58.1A8.57,8.57,0,0,0,47,37.73Z"
    />
    <path
      className="cls-27"
      d="M49,37.57h.34l.26.09-.24.14a1.73,1.73,0,0,1,0,.32A13.75,13.75,0,0,0,49,39.8H48.6c0-.31,0-.6.1-.88s.11-.45.16-.68S48.92,37.79,49,37.57Z"
    />
    <path
      className="cls-22"
      d="M49,39.8a13.75,13.75,0,0,1,.24-1.69,1.73,1.73,0,0,0,0-.32l.24-.14h.09a21.25,21.25,0,0,1-.34,2.24h-.09Z"
    />
    <path
      className="cls-16"
      d="M67.2,9.65V9.39l.16-.25h.13c.52.11,1,.23,1.57.36.31.07.61.16.92.24,0,.11,0,.22,0,.34l-.17.18a1.34,1.34,0,0,0-.31-.17,4.3,4.3,0,0,0-.69-.16A8.75,8.75,0,0,0,67.2,9.65Z"
    />
    <path
      className="cls-24"
      d="M70,9.74c-.31-.08-.61-.17-.92-.24-.52-.13-1-.25-1.57-.36h-.13a.79.79,0,0,1,.18-.55c.11.23.36.22.54.27a13.32,13.32,0,0,0,1.39.32c.15,0,.31,0,.46,0l.21.09v.08C70.1,9.51,70,9.62,70,9.74Z"
    />
    <path
      className="cls-5"
      d="M22.32,15.56h.33l.27.2c0,.23-.05.52-.1.81-.07.46-.15.93-.25,1.4a1.89,1.89,0,0,1-.21.54c-.33.55-.71,1.08-1,1.64a5.32,5.32,0,0,0-.49,1.33,2.74,2.74,0,0,1-.17.48.23.23,0,0,1-.26.17,3.66,3.66,0,0,0-.69-1.38.61.61,0,0,0-.16-.46.63.63,0,0,1,0-.77c.27-.42.5-.87.79-1.28a1.25,1.25,0,0,1,1.1-.42.55.55,0,0,0,.55-.36c0-.2.06-.41.09-.62s.1-.62.15-1l-.15-.23V15.6Z"
    />
    <path
      className="cls-20"
      d="M50.41,37.91c0,.27,0,.53-.09.8s-.11.57-.16.86l-.09.49L49.72,40h0v-.12c.05-.4.11-.8.17-1.19s.12-.56.17-.85Z"
    />
    <path className="cls-18" d="M61.71,37.91l.08-.09Z" />
    <path
      className="cls-15"
      d="M69.55,12.15a8.27,8.27,0,0,1-1.71-.29l-1-.23c0-.17-.08-.36.06-.52.26,0,.51.05.76.09a3.7,3.7,0,0,1,1,.21,2.43,2.43,0,0,0,.91.14h0a3.62,3.62,0,0,1,0,.47Z"
    />
    <path
      className="cls-18"
      d="M50.07,37.81c0,.29-.12.57-.17.85s-.12.79-.17,1.19V40l-.43-.08a21.25,21.25,0,0,0,.34-2.24A.46.46,0,0,1,50.07,37.81Z"
    />
    <path
      className="cls-27"
      d="M15.69,34.89a.28.28,0,0,1,.27.24,4,4,0,0,0-.44.93c-.22.53-.41,1.06-.61,1.59h-.17l-.09-.17a1.22,1.22,0,0,1,0-.34c.26-.68.54-1.36.82-2Z"
    />
    <path
      className="cls-25"
      d="M14.91,37.65c.2-.53.39-1.06.61-1.59a4,4,0,0,1,.44-.93l.24.19a8.34,8.34,0,0,1-.5,1.34c-.19.41-.36.83-.53,1.25Z"
    />
    <path
      className="cls-18"
      d="M15.17,37.91c.17-.42.34-.84.53-1.25a8.34,8.34,0,0,0,.5-1.34.72.72,0,0,1,.37.34l-.18.15A4,4,0,0,1,16,36.9a3.34,3.34,0,0,1-.58,1.18h-.08C15.21,38.09,15.15,38,15.17,37.91Z"
    />
    <path
      className="cls-16"
      d="M15.69,34.89l-.18.21c-.28.68-.56,1.36-.82,2a1.22,1.22,0,0,0,0,.34l-.26-.18v-.08c.13-.38.25-.76.4-1.13a8.39,8.39,0,0,0,.44-1,.64.64,0,0,1,.28-.42Z"
    />
    <path
      className="cls-24"
      d="M15.51,34.64a.64.64,0,0,0-.28.42,8.39,8.39,0,0,1-.44,1c-.15.37-.27.75-.4,1.13a.52.52,0,0,1,0-.54c.14-.38.29-.75.44-1.13A5.27,5.27,0,0,0,15,35a1.17,1.17,0,0,1,.36-.59h.07Z"
    />
    <path
      className="cls-22"
      d="M67.05,10h.28c.57.11,1.15.2,1.71.36a1.68,1.68,0,0,1,.68.24c0,.11,0,.23,0,.34l-.09.18L69.43,11c-.45-.11-.89-.21-1.34-.3L67,10.43C67,10.28,67,10.14,67.05,10Z"
    />
    <path
      className="cls-27"
      d="M69.72,10.6a1.68,1.68,0,0,0-.68-.24c-.56-.16-1.14-.25-1.71-.36h-.21c0-.11,0-.23.08-.34a8.75,8.75,0,0,1,1.61.28,4.3,4.3,0,0,1,.69.16,1.34,1.34,0,0,1,.31.17C69.77,10.37,69.89,10.52,69.72,10.6Z"
    />
    <path
      className="cls-18"
      d="M67,10.43l1.06.22c.45.09.89.19,1.34.3l.21.17,0,.43a2.43,2.43,0,0,1-.91-.14,3.7,3.7,0,0,0-1-.21c-.25,0-.5-.06-.76-.09V10.6Z"
    />
    <path
      className="cls-15"
      d="M15.42,38.08A3.34,3.34,0,0,0,16,36.9a4,4,0,0,0,.39-1.09l.18-.15.15.09c.07.27-.11.48-.19.71-.23.63-.5,1.25-.76,1.87C15.58,38.35,15.56,38.14,15.42,38.08Z"
    />
    <path className="cls-17" d="M17.75,20.25l-.08-.09Z" />
    <path
      className="cls-16"
      d="M23.44,13.17c.18-.08.35-.15.52,0a12.57,12.57,0,0,1-.2,1.5c-.07.3,0,.59-.07.88l-.51,0c0-.45.06-.9.11-1.34S23.39,13.5,23.44,13.17Z"
    />
    <path
      className="cls-21"
      d="M23.44,13.17c-.05.33-.11.66-.15,1s-.07.89-.11,1.34h-.43a8,8,0,0,1,.16-1.38c.06-.31.07-.64.1-1,.06-.11.15-.08.24-.05Z"
    />
  </SVG>
)

export default Lock

const SVG = styled.svg`
  .cls-1 {
    fill: #609dd8;
  }
  .cls-2 {
    fill: #4385bc;
  }
  .cls-3 {
    fill: #c2c2c2;
  }
  .cls-4 {
    fill: #65a0d9;
  }
  .cls-5 {
    fill: #bcbcbc;
  }
  .cls-6 {
    fill: #4e8cc0;
  }
  .cls-7 {
    fill: #6198c6;
  }
  .cls-8 {
    fill: #bebebe;
  }
  .cls-9 {
    fill: #6a9eca;
  }
  .cls-10 {
    fill: silver;
  }
  .cls-11 {
    fill: #c4c4c4;
  }
  .cls-12 {
    fill: #528ec1;
  }
  .cls-13 {
    fill: #5892c3;
  }
  .cls-14 {
    fill: #f3f3f3;
  }
  .cls-15 {
    fill: #cecece;
  }
  .cls-16 {
    fill: #e0e0e0;
  }
  .cls-17 {
    fill: #cbcbcb;
  }
  .cls-18 {
    fill: #d4d4d4;
  }
  .cls-19 {
    fill: #c8c8c8;
  }
  .cls-20 {
    fill: #d1d1d1;
  }
  .cls-21 {
    fill: #e8e8e8;
  }
  .cls-22 {
    fill: #d9d9d9;
  }
  .cls-23 {
    fill: #f0f0f0;
  }
  .cls-24 {
    fill: #e4e4e4;
  }
  .cls-25 {
    fill: #d7d7d7;
  }
  .cls-26 {
    fill: #ebebeb;
  }
  .cls-27 {
    fill: #ddd;
  }
  .cls-28 {
    fill: #f7f7f7;
  }
  .cls-29 {
    fill: #6aa3da;
  }
`
