// Rotation

// ___________________________________________________________________

import * as React from 'react'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

const Rotation = () => (
  <svg viewBox="0 0 50.57 50.57">
    <title>Rotation icon</title>
    <path d="M25.29.84A24.45,24.45,0,0,1,42.57,42.58,24.45,24.45,0,0,1,8,8,24.29,24.29,0,0,1,25.29.84m0-.84A25.29,25.29,0,1,0,43.17,7.41,25.19,25.19,0,0,0,25.29,0Z" />
    <polygon
      fillRule="evenodd"
      points="36.44 10.6 45.62 1.5 45.64 10.72 36.44 10.6"
    />
    <path d="M45.16,2.61l0,7.64-7.63-.1,7.61-7.54M46.08.39,35.32,11l10.79.15,0-10.8Z" />
    <polygon
      fillRule="evenodd"
      points="4.91 39.88 14.11 40 4.93 49.1 4.91 39.88"
    />
    <path d="M5.37,40.35l7.63.1L5.39,48l0-7.64m-.93-.94,0,10.8L15.23,39.55,4.44,39.41Z" />
  </svg>
)

export default Rotation
