// FeatureStack

// ___________________________________________________________________

import * as React from 'react'
import styled from 'styled-components'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

const FeatureStack = () => (
  <SVG viewBox="0 0 91.19 286.37">
    <title>Features icon</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="BG">
        <circle className="cls-1" cx="40.84" cy="139.04" r="40.43" />
        <circle className="cls-1" cx="41.59" cy="41.59" r="41" />
        <path
          className="cls-1"
          d="M79.09,211.92,79,246c0,7.7-7,17.18-18.76,27.05a155,155,0,0,1-13.37,10c-1.53,1.08-3,2-4.41,2.84h0c-1.29-.83-2.76-1.79-4.36-2.87A161.91,161.91,0,0,1,24.88,273C13.13,263,6.12,253.52,6,245.88l.07-36.8a134.85,134.85,0,0,0,19.28-5.78,118,118,0,0,0,15.73-8c.5-.34,1-.64,1.53-.93a16.19,16.19,0,0,1,1.52.93,121,121,0,0,0,15.64,8.07,78.1,78.1,0,0,0,16.54,4.92c1,.19,1.52.28,1.87.37C79.58,208.94,79.09,208,79.09,211.92Z"
        />
        <path
          className="cls-2"
          d="M79.09,211.92,79,246c0,7.7-7,17.18-18.76,27.05a155,155,0,0,1-13.37,10c-1.53,1.08-3,2-4.41,2.84h0c-1.29-.83-2.76-1.79-4.36-2.87A161.91,161.91,0,0,1,24.88,273C13.13,263,6.12,253.52,6,245.88l.07-36.8a134.85,134.85,0,0,0,19.28-5.78,118,118,0,0,0,15.73-8c.5-.34,1-.64,1.53-.93a16.19,16.19,0,0,1,1.52.93,121,121,0,0,0,15.64,8.07,78.1,78.1,0,0,0,16.54,4.92c1,.19,1.52.28,1.87.37C79.58,208.94,79.09,208,79.09,211.92Z"
        />
        <path
          className="cls-3"
          d="M36.14,223.75a7.11,7.11,0,1,1-7.09-7.12,7.1,7.1,0,0,1,7.09,7.12Z"
        />
        <path
          className="cls-3"
          d="M21,254.64l0-18.84A2.87,2.87,0,0,1,23.85,233l10.34,0A2.88,2.88,0,0,1,37,235.83"
        />
        <path
          className="cls-3"
          d="M61.1,220.53A7.11,7.11,0,1,1,54,213.4a7.11,7.11,0,0,1,7.09,7.13Z"
        />
        <path
          className="cls-3"
          d="M45.94,235.25v-2.77a2.8,2.8,0,0,1,2.86-2.75l10.34,0A2.86,2.86,0,0,1,62,232.6l0,19"
        />
        <path
          className="cls-3"
          d="M49,243.13a7.12,7.12,0,1,1-7.1-7.13h0a7.11,7.11,0,0,1,7.1,7.12Z"
        />
        <path
          className="cls-3"
          d="M33.82,262.06v-6.89a2.86,2.86,0,0,1,2.86-2.84l10.34,0a2.85,2.85,0,0,1,2.84,2.85v6.89"
        />
        <line className="cls-4" x1="71.94" y1="14.04" x2="13.92" y2="71.85" />
        <path d="M36.4,66.89l-.51-3.13-.54.05c-.4,0-.79,0-1.18,0a13.53,13.53,0,0,1-7-2l-.46-.29-1.85,2.58.52.69L24.07,64c-.23-.16-.73-.51-1-.78a2.88,2.88,0,0,1,.53.25l.45.25,1.91-2.64-.42-.35a13.51,13.51,0,0,1-4.58-7.86l-.1-.56-3.13.51-.05.43c0,.31-.08.57-.12.79-.09-.38-.2-.9-.31-1.61S17,51.15,17,50.75l.36.71.19.39,3.18-.51,0-.52a13.37,13.37,0,0,1,2-7.5l.28-.46L20.38,41l-.34.27c-.24.18-.44.33-.62.45.23-.36.56-.84,1-1.47s.64-.85.87-1.13c-.06.2-.14.43-.23.7l-.15.42,2.58,1.85.35-.41A13.38,13.38,0,0,1,31,37.25l.53-.13L31.05,34l-.43-.06c-.3,0-.55-.07-.77-.11.39-.08.9-.18,1.59-.3.29,0,1-.16,1.63-.22l-.69.35-.39.19L32.5,37l.54,0a11.77,11.77,0,0,1,1.18-.05,13.43,13.43,0,0,1,7,2l.46.28,1.86-2.58-.28-.35c-.28-.35-.48-.62-.63-.84a13.8,13.8,0,0,1,1.65,1,11.83,11.83,0,0,1,1.43,1.19l-1-.34-.42-.15-1.86,2.58.42.35a13.39,13.39,0,0,1,4.25,6.49l.14.5,3.27-.53,0-.52a5.92,5.92,0,0,1,0-.73c.08.39.18.93.29,1.66.07.46.13.91.19,1.32-.07-.12-.4-.79-.4-.79l-3.12.51.08.57a13.5,13.5,0,0,1-1.86,8.9l-.28.46L48,59.72l.82-.61c-.3.44-.62.89-.84,1.19s-.53.74-.85,1.15c.06-.16.32-1,.32-1l-2.57-1.86-.35.42a13.44,13.44,0,0,1-7.17,4.43l-.53.13.51,3.12.56.09-1,.17-1,.16Z" />
        <path d="M34.22,37.45a12.88,12.88,0,0,1,6.72,1.9l.82.5.75.63a12.82,12.82,0,0,1,4.07,6.21l.27.92.14,1a12.9,12.9,0,0,1-1.78,8.51l-.51.84-.62.73a12.74,12.74,0,0,1-6.86,4.24l-.95.23-1,.08a10.8,10.8,0,0,1-1.13.05,12.87,12.87,0,0,1-6.72-1.89l-.85-.52-.72-.61a12.87,12.87,0,0,1-4.38-7.51l-.18-1,0-1a12.78,12.78,0,0,1,1.89-7.18l.51-.84.62-.73a12.8,12.8,0,0,1,6.86-4.24l.95-.22,1-.09c.38,0,.76,0,1.13,0m-.38-4.79a17.31,17.31,0,0,0-2.49.28c-.69.11-3.07.5-3.05.92,0,.18.4.41,2.24.65l.36,2.17a14,14,0,0,0-7.49,4.62L21.63,40c.61-1.75.58-2.22.43-2.3l-.06,0c-.41,0-1.55,1.55-2,2.2s-1.95,2.66-1.62,3a.14.14,0,0,0,.11,0c.22,0,.74-.25,1.93-1.17L22.18,43a14,14,0,0,0-2.06,7.83l-2.24.37c-.76-1.57-1.11-1.93-1.29-1.93h0c-.43.13,0,2.65.07,3.22s.5,3,.94,3h0c.18,0,.41-.41.65-2.24L20.35,53a14,14,0,0,0,4.78,8.2l-1.29,1.77a2.45,2.45,0,0,0-1.06-.38.79.79,0,0,0-.23,0,.62.62,0,0,0-.34.31c-.1.37.53.87,1.52,1.56a12.17,12.17,0,0,0,2.87,1.78.12.12,0,0,0,.09,0c.11-.12,0-.55-1.13-2l1.28-1.78a14,14,0,0,0,7.33,2.06c.41,0,.82,0,1.23,0l.36,2.17c-1.68.86-2,1.18-1.93,1.32s.07.07.26.07a24.67,24.67,0,0,0,2.9-.38c2.8-.46,3.1-.58,3.1-.71s-.38-.36-2.24-.64l-.36-2.17A14.09,14.09,0,0,0,45,59.43l1.78,1.28c-.6,1.77-.57,2.22-.43,2.3h.05c.36,0,1.33-1.35,2.07-2.37S50.29,58.1,50,57.86a.15.15,0,0,0-.1,0c-.22,0-.73.25-1.94,1.17l-1.79-1.29a14,14,0,0,0,1.94-9.29l2.13-.34C51.06,49.64,51.4,50,51.57,50h0c.26-.08.09-1.21-.2-3.19-.43-2.91-.7-3.06-.82-3.06h0c-.2,0-.42.39-.5.68A5.16,5.16,0,0,0,49.94,46l-2.24.37a14,14,0,0,0-4.44-6.78l1.29-1.78a7,7,0,0,0,2,.54.34.34,0,0,0,.3-.11c.21-.37-1.27-1.64-2.2-2.31a8.2,8.2,0,0,0-2.77-1.46.24.24,0,0,0-.18.06c-.13.15,0,.61,1.13,2l-1.28,1.78a14,14,0,0,0-7.33-2.07q-.61,0-1.23.06l-.36-2.17c1.67-.81,2-1.16,1.93-1.32s-.33-.19-.72-.19Z" />
        <path d="M60.14,39.23l-.72-1.74-.31.1A8,8,0,0,1,56.6,38a8.11,8.11,0,0,1-2.49-.4l-.31-.1-.71,1.73a3.28,3.28,0,0,0,.26.22L52.23,39l.34,0,.71-1.74L53,37.14a8,8,0,0,1-3.54-3.53l-.15-.29L47.56,34s0,.43,0,.51c-.11-.25-.24-.54-.37-.85A6.59,6.59,0,0,1,47,33a2.07,2.07,0,0,1,.19.29l.15.27,1.79-.74L49,32.5a8,8,0,0,1,.13-5.4l.12-.31-1.74-.72-.16.2a4.54,4.54,0,0,1-.31.36c.08-.22.19-.51.35-.9s.29-.69.4-.91c0,.13,0,.29,0,.47l0,.26,1.77.73.15-.27A7.91,7.91,0,0,1,53,22.84l.28-.15L52.53,21l-.71.07c.23-.11.54-.26,1-.43s.57-.23.78-.3l-.53.44.72,1.74.31-.1a8.24,8.24,0,0,1,2.51-.4,7.82,7.82,0,0,1,2.49.4l.31.1.72-1.75-.56-.45c.23.07.52.18.9.34s.68.29.89.39l-.46,0-.25,0-.72,1.74.29.15a7.9,7.9,0,0,1,3.54,3.53l.15.29,1.74-.73,0-.26c0-.27,0-.47-.06-.62a8.89,8.89,0,0,1,.52,1,8.57,8.57,0,0,1,.35,1.05l-.4-.48-.16-.2-1.75.72.1.31a7.92,7.92,0,0,1,.13,4.6l-.08.3,1.82.74.15-.26a2.6,2.6,0,0,1,.23-.37c-.08.22-.2.52-.37.93l-.32.72c0-.08,0-.52,0-.52l-1.74-.71-.14.31a8,8,0,0,1-3.71,3.92l-.28.14L60.66,39l.6,0-.79.34-.79.31Z" />
        <path d="M56.59,22.32A7.41,7.41,0,0,1,59,22.7l.55.18.52.26a7.57,7.57,0,0,1,3.39,3.37l.26.52.19.55A7.67,7.67,0,0,1,64,32l-.15.55-.24.54a7.68,7.68,0,0,1-3.54,3.75l-.52.26-.54.18a7.6,7.6,0,0,1-2.41.39,7.7,7.7,0,0,1-2.38-.38l-.55-.18-.52-.26a7.59,7.59,0,0,1-3.39-3.38l-.27-.53-.18-.53a7.68,7.68,0,0,1,.13-5.16l.21-.55.28-.5a7.64,7.64,0,0,1,3.19-3l.53-.27.53-.18a8,8,0,0,1,2.41-.38m2.31-2.56c-.07,0-.13,0-.15,0s.08.33.94,1L59.19,22a8.37,8.37,0,0,0-2.6-.42A8.55,8.55,0,0,0,54,22l-.5-1.2c.85-.69,1-.94.93-1a.17.17,0,0,0-.14,0,6.72,6.72,0,0,0-1.6.51c-.34.13-1.81.73-1.74,1,0,.06.13.12.51.12.21,0,.49,0,.88-.06l.5,1.21a8.27,8.27,0,0,0-3.48,3.31l-1.25-.51c.12-1.1,0-1.36-.06-1.39h0c-.27,0-.82,1.35-.94,1.65s-.7,1.71-.48,1.83h0c.12,0,.38-.19,1-.94l1.18.49a8.39,8.39,0,0,0-.14,5.63l-1.2.5c-.23-.41-.39-.55-.54-.58h-.09a.29.29,0,0,0-.18,0c-.17.16,0,.61.28,1.26.52,1.2.79,1.81.94,1.81h0c.1,0,.17-.28.06-1.39l1.21-.5a8.31,8.31,0,0,0,3.7,3.69l-.5,1.2-.83,0c-.42,0-.54.06-.55.11s.14.19,1.69.83a9.51,9.51,0,0,0,1.73.63l.06,0c0-.08-.08-.3-.94-1l.5-1.2a8.4,8.4,0,0,0,2.6.41,8.26,8.26,0,0,0,2.62-.42l.5,1.2c-.85.71-1,1-.93,1s.06,0,.12,0a7.37,7.37,0,0,0,1.69-.57c.73-.3,1.72-.72,1.67-.92s-.13-.11-.52-.11a8.49,8.49,0,0,0-.87,0l-.5-1.2a8.34,8.34,0,0,0,3.87-4.09l1.19.48c-.1,1.11,0,1.37.06,1.39h0c.15,0,.42-.62.87-1.68.69-1.6.6-1.76.55-1.8l-.07,0a.71.71,0,0,0-.4.21,3.24,3.24,0,0,0-.56.75l-1.24-.52a8.33,8.33,0,0,0-.13-4.8l1.2-.5c.59.75.86.94,1,.94h0c.22-.12-.14-1.22-.39-1.85s-.78-1.63-1-1.63h0c-.11,0-.2.31-.06,1.39l-1.21.5a8.24,8.24,0,0,0-3.7-3.68l.5-1.21a8.18,8.18,0,0,0,.86.05c.39,0,.51-.05.52-.12.07-.25-1.27-.8-1.65-.95a7.16,7.16,0,0,0-1.68-.53Z" />
        <line className="cls-5" x1="44.79" y1="41.09" x2="21.87" y2="63.92" />
        <line className="cls-5" x1="62.21" y1="23.73" x2="50.52" y2="35.38" />
        <circle className="cls-6" cx="41.59" cy="41.59" r="41" />
        <path
          className="cls-7"
          d="M11.09,42.55a5.77,5.77,0,0,1-2.35-1.39,5.86,5.86,0,0,1-1.55-2.67"
        />
        <path
          className="cls-7"
          d="M47.91,71.4a5.82,5.82,0,0,1-1.4,2.35,5.74,5.74,0,0,1-2.66,1.55"
        />
        <path
          className="cls-8"
          d="M68.53,43.62A5.82,5.82,0,0,1,67.13,46a5.74,5.74,0,0,1-2.66,1.55"
        />
        <path
          className="cls-9"
          d="M36.44,23.42a8.86,8.86,0,0,1,1.63-1c1.36-.63,1.9-.5,2.43-1,.9-.79-.19-1.71.65-2.44,1-.87,3.06-.37,3.56-1.1.34-.5-.46-1-.09-1.45s1.31-.48,2.2-.47"
        />
        <path
          className="cls-10"
          d="M24.93,150.52C20,120.6,46.36,121.75,59.25,113.15c6.17,25.08-7.12,44.65-30.78,42.83L19.56,164c-.94-2.37-.84-3.94,1.15-5.46,1.94-1.82,3.61-3.29,5.55-5.11,11.42-10.27,22.68-21.77,30.32-34.65"
        />
        <path
          className="cls-6"
          d="M73.32,115.29C64.54,104,49.81,97,35.62,98.91A40.31,40.31,0,0,0,1,144.2M7,160.35a40.26,40.26,0,0,0,74-26.85"
        />
        <path className="cls-11" d="M5.09,168.29V158.14l10.14.16Z" />
        <path className="cls-11" d="M75.18,107.12l-.43,10.22-10.18-.59Z" />
        <circle className="cls-12" cx="75.57" cy="258.46" r="15.24" />
        <polyline
          className="cls-13"
          points="66.99 259.42 72.11 264.4 84.17 252.52"
        />
        <circle className="cls-12" cx="75.76" cy="156.82" r="15.24" />
        <polyline
          className="cls-13"
          points="67.18 157.79 72.3 162.77 84.36 150.88"
        />
        <circle className="cls-12" cx="75.95" cy="55.54" r="15.24" />
        <polyline
          className="cls-13"
          points="67.36 56.51 72.49 61.49 84.55 49.6"
        />
      </g>
    </g>
  </SVG>
)

export default FeatureStack

const SVG = styled.svg`
  .cls-1 {
    fill: #fff;
  }

  .cls-10,
  .cls-13,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    fill: none;
  }

  .cls-10,
  .cls-2,
  .cls-3,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    stroke: #000;
  }

  .cls-13,
  .cls-2,
  .cls-3,
  .cls-6 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .cls-2 {
    stroke-width: 0.95px;
  }

  .cls-3 {
    stroke-width: 1.16px;
  }

  .cls-4 {
    stroke: #211e1f;
  }

  .cls-10,
  .cls-4,
  .cls-5,
  .cls-7,
  .cls-8,
  .cls-9 {
    stroke-miterlimit: 10;
  }

  .cls-4,
  .cls-6 {
    stroke-width: 1.17px;
  }

  .cls-13,
  .cls-5 {
    stroke: #fff;
  }

  .cls-5 {
    stroke-width: 2.34px;
  }

  .cls-7 {
    stroke-width: 1.92px;
  }

  .cls-7,
  .cls-8 {
    stroke-dasharray: 2.34;
  }

  .cls-8 {
    stroke-width: 1.76px;
  }

  .cls-10,
  .cls-9 {
    stroke-width: 0.89px;
  }

  .cls-10,
  .cls-11 {
    fill-rule: evenodd;
  }

  .cls-12 {
    fill: #4172ad;
  }

  .cls-13 {
    stroke-width: 2.36px;
  }
`
